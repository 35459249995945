export default {
  en: {
    // Platform Home Page
    for: 'For',
    cardholders: 'Cardholders',
    admins: 'Admins',
    cardholdersLandingSubheading: 'Did you receive a GiveCard and are looking for more information?',
    adminLandingSubheading: 'Log into your GiveCard Dashboard!',

    cardholderWelcome: 'Hope Begins Here',
    cardholderSubWelcome: 'We are happy you have received your GiveCard.',
    memberSubWelcome: "We're happy you are are joining us.",

    // CardholderLogin Component
    login: 'Login',
    enterAValidEmail: 'Enter a valid email',
    or: 'OR',
    exploreCard: 'Want to explore more about your card?',
    createAnAccount: 'Create Account',
    email: 'Email',
    emailOrPhone:  'Email or Phone Number',
    password: 'Password',
    welcomeBack: 'Welcome Back!',
    enterPassword: 'Please enter your password',
    enterOneTimePassword: 'Please enter the 6 digit code',
    codeSentTo: 'Code sent to',
    passwordReminder: 'Reminder. 8 characters including 1 number and 1 special character',
    forgotYourPassword: 'Forgot your password? Reset it',
    here: 'here',
    resetYourPassword: 'Reset Your Password',
    sendResetPasswordLink: 'Send Reset Password Link',
    resetLinkInstructions: 'A link to reset your password has been sent to ',
    checkSpamFolder: 'If you do not see the link, check your spam folder.',
    loginAgain: 'Login again',
    dontHaveAccount: "Don't have an account? Please create an account via the link that was sent to your email.",
    verifyPhoneNumber: 'Verify Your Phone Number',
    sendOTP: 'Send Verification Code',
    verifyOTP: 'Verify Verification Code',
    accountNotActivatedYet: 'Your account has not been activated yet. Please check your email or phone number for the activation link.',
    noAccountWithPhoneNumber: 'There is no account associated with this phone number.',

    // QuickResources Component Variables
    quickResources: 'Quick Resources',
    checkBalance: 'Check Balance',
    findAnATM: 'Find an ATM',
    setPin: 'Set Pin',
    setPinInfo: 'This is a four digit code that should not be shared with anyone',

    // Frequently Asked Questions
    faqHeader: 'Frequently Asked Questions',
    faqQuestion1: 'Do I need to activate my card before using it?',
    faqAnswer1: 'No, you do not need to do anything before you begin using your card. As long as there is a balance on your card, you are good to use your card.',
    faqQuestion2: 'Where can I use my new card?',
    faqAnswer2: 'Anywhere that accepts debit cards, as long as your program does not have any spending rules. Restrictions may also apply in stores that require ID. If your organization does have spending rules, they will tell you prior to distributing the card to you.',
    faqQuestion3: 'Can I use my card at an ATM to withdraw cash?',
    faqAnswer3: 'It depends on if your organization allows ATM withdraw. Please contact your organization to find out. If they do, you need to set a PIN first. Set your PIN by clicking the "Set Pin" button above.',
    faqQuestion4: 'How do I set my PIN?',
    faqAnswer4: 'By clicking the "Set Pin" button, you can start the process of setting your PIN. Have your card on hand. Follow the instructions.',
    faqQuestion5: 'How do I reset my PIN?',
    faqAnswer5: 'By clicking the "Set Pin" button, you can start the process of resetting your PIN. Have your card on hand. Follow the instructions. They will appear the same as the first time you set a pin.',
    faqQuestion6: 'I lost my card, or someone stole it. What should I do?',
    faqAnswer6: 'Contact GiveCard support (email below call at 908-529-0955). GiveCard will go through the process of locking the card and sending you a new one.',
    faqQuestion7: 'My card got declined. Why?',
    faqAnswer7: 'There are several reasons why a card might have been declined. The most common problem is if you do not have a sufficient balance. Other problems include clicking cancel when it asks for a pin, a card reader error or your organizations have set spending rules. Contact your organization to find out. Other merchants are able to decline based on their own transaction and decline rules. You may be able to resolve this problem by contacting the merchant directly, and they may be able to offer an override if you confirm your card issuer approved the transaction.',
    faqQuestion8: 'What billing address should I use for online purchases?',
    faqAnswer8: 'If you have a physical card, please make an account and click "Billing Address" inside the cardholder portal or contact GiveCard support. If you have a virtual card, the billing address is 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: 'Who do I contact about insufficient funds?',
    faqAnswer9: 'Contact the organization who gave you the card. The organization will be able to inform you about your fund balance.',

    // Additional Support Footer
    furtherQuestionsInfo: 'For further questions or issues, please contact ',
    supportEmailAndPhoneNumber: 'or call 908-529-0955',
    customerSupportHours: 'Customer support is available from 9 a.m. to 6 p.m. E.S.T Monday through Friday.*',
    holidaysSupportException: '*Holidays will affect these hours',

    // Card Details
    confirmCardInformation: 'Confirm Card Information',
    last6Digits: 'Last 6 Digits of Card Number',
    organizationName: 'Organization Name on Back of Card',
    expirationDate: 'Expiration Date',
    invalidError: 'Invalid card data. Try again',

    // Set Pin
    last6Error: 'Last 6 digits input format invalid',
    expirationError: 'Expiration date input format invalid',
    setNewPin: 'Set New Pin',
    confirmNewPin: 'Confirm New Pin',
    pinLengthError: 'Pin needs to be 4 digits.',
    pinMatchingError: 'Pins do not match.',
    pinSetSuccess: 'Success!',
    yourNewPinIs: 'Your new pin is:',
    doNotSharePin: 'Do not share your pin with anyone else.',
    pinInstructions: 'You can now use this pin for any transaction or withdrawal that asks for a pin.',
    alreadySetPin: 'It looks like your PIN has already been set!',
    accountLoginNeededForPinReset: 'Please log into your GiveCard account to reset your PIN or reach out to support at',
    oops: 'Oops!',
    expirationDateExample: 'MM/YY',
    yourCardIs: 'Your card is',
    cantSetPinWithThisStatus: "You can't set a pin on a card with this status.",
    reachOutToYourOrgOrGiveCard: 'Please reach out to your organization or to',
    ifItsAMistake: 'if you believe this is a mistake.',

    // Lock Card
    unlockCardPromptHeader: 'Would you like to unlock this card?',
    unlockCardPromptDetails: 'The card will instantly allow transactions to resume and funds to be spent.',
    unlockCardButton: 'Yes, unlock this card.',
    unlockSuccessful: 'Your card has been successfully unlocked.',

    lockCardPromptHeader: 'Are you sure you want to lock this card?',
    lockCardPromptDetails: 'The card will no longer be able to make transactions and access funds while locked. You will be able to unlock this card at any time.',
    lockCardButton: 'Yes, lock this card.',
    lockSuccessfulHeader: 'Your card has been successfully locked.',
    lockSuccessfulDetails: 'This card will not be able to use any funds or complete any transactions as of this moment.',
    unlockHowTo: 'If you want to unlock this card, hover over the status on the individual card page and it will automatically direct you through the unlocking process.',

    // Check Balance
    currentBalance: 'Current Balance',
    yourCardBalance: 'Your card balance is:',
    contactSupportEmail: 'For any questions or issues, please contact support',

    // Create an Account
    createNewAccountSubheading: 'Before creating an account, we need to confirm your GiveCard details. You will only need to do this once!',
    wantToKnowMore: 'Want to know more information about your card?',
    createAPassword: 'Create a password',
    passwordRequirements: 'Password requirements: 8 characters, including 1 number and 1 special character',
    confirm: 'Confirm',
    confirmPassword: 'Confirm Password',
    alreadyHaveAnAccount: 'Already have an account? Login ',
    accountAlreadyExistsError: 'Whoops! An account with this email already exists.',
    cardAlreadyLinkedError: 'This card is already linked with another account',
    accountCreationError: 'Error creating account.',
    error: 'Error',
    tryAgainMessage: 'Please try again, or contact support',
    passwordMismatchError: 'Passwords must match.',
    passwordInvalidError: 'Password must contain 8 characters, including at least one number and one special character.',
    welcome: 'Welcome!',
    invalidTokenPage: 'Sorry, incorrect link! Please click the link we sent you in the email to create an account.',
    createAccountToAccessVirtualCard: 'Create an account to access your Virtual Card!',
    alreadyHaveAccount: 'Already have an account?',
    firstName: 'First Name',
    lastName: 'Last Name',
    firstAndLastName: 'First and Last Name',
    phoneNumber: 'Phone Number',
    createAcctToViewPortal: "Create an account to view your organization's portal.",
    yourEmailAddress: 'Your Email Address',
    haventMetPasswordRequirements: 'You have not met all of the password requirements.',
    createProfile: 'Create Profile',
    creatingUser: 'Creating User',
    invalidEmail: 'Invalid Email',
    invalidPhoneNumber: 'Invalid Phone Number',
    phoneRequirements: 'Needs to have 10 digits, no dashes',
    createAccountSubheading: "Let's gather some basic info!",
    enterMobilePhoneNumber: 'Please enter your mobile phone number',
    requirements: 'Requirements:',
    atLeast8Chars: '• At least 8 characters',
    oneNumber: '• 1 number',
    oneSpecialCharacter: '• 1 special character like',
    passwordsMatch: '• Passwords match',
    alreadyHaveAdminAccount: 'Already have a GiveCard Admin account?',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'Check out the notifications feature! You are now able to receive email notifications on your recent transactions. Click on the notifications button for more information.',
    cardActivityHistory: 'Card Activity History',
    transactions: 'Transactions',
    loadHistory: 'Load History',
    date: 'Date',
    transaction: 'Transaction',
    merchantCategory: 'Merchant Category',
    amount: 'Amount',
    timeLoaded: 'Time Loaded',
    amountLoaded: 'Amount Loaded',
    lockCard: 'Lock Card',
    unlockCard: 'Unlock Card',
    home: 'Home',
    emailNotificationsHeader: 'Email Notifications',
    emailNotificationsSubheader1: 'Get notifications sent to your email on any card activity.',
    emailNotificationsSubheader2: 'You can turn these off at any time.',
    notifications: 'Notifications',
    recentTransactions: 'Recent Transactions',
    recentTransactionsSubheader: 'Receive notifications on your recent transactions to keep track of spend and prevent fraud.',
    cardDeclines: 'Card Declines',
    cardDeclinesSubheader: 'Receive a notification when your card declines to understand why.',
    cardLoads: 'Card Loads',
    cardLoadsSubheader: 'Receive a notification when funds are added to your card.',
    sendingTo: 'Sending to: ',
    saving: 'Saving...',
    save: 'Save',
    cardIsFrozen: 'Your card has been frozen by your organization.',
    cardIsClosed: 'Your card was closed by your organization.',
    contactAdministrator: 'If you have any questions, please reach out to the organization that provided you the card.',
    cardCanOnlyBeUsedAt: 'Your program only allows this card to be used at the following merchants:',
    moreInfoAboutMerchantRestrictions: 'For more information about merchant restrictions, please contact your program directly. GiveCard is not responsible for these restrictions.',
    getRewardsButton: 'Get $5',

    // Transaction Table
    pending: 'Pending',
    pendingPurchases: 'Pending Purchases',
    pendingPurchasesDescription: 'Pending purchases are charges that have been authorized by the merchant, but not yet posted, and typically post within 5 days. They are included in your current balance.',
    cleared: 'Cleared',
    clearedPurchases: 'Cleared Purchases',
    clearedPurchasesDescription: 'Cleared Purchases have been authorized by the merchant and are approved.',
    loadingTxs: 'Loading Transactions',

    // Full Card Details
    errorGettingCardDetails: 'Error getting card details.',
    pleaseRefreshThePage: 'Please refresh the page.',
    cardNumber: 'Card Number',
    howDoIUseMyVirtualCard: 'How do I use my virtual card?',
    usingAVirtualCard: 'Using a Virtual Card',
    clickThe: 'Click the',
    toSeeFullCardDetails: 'to see the full card details',
    cardCanBeUsedAnywhere: 'This card can be used anywhere that accepts a pre-paid Mastercard',
    cardInstructions: 'Use this card online by copying and pasting the details displayed in this portal',
    digitalWalletInstructions: 'Add your card to',
    digitalWalletInstructions2: 'by entering in the card details on your phone. Further instructions linked.',
    orLowercase: 'or',
    billingAddress: 'If a purchase requires a billing address, please use the following:',
    needSupport: 'Need support?',
    teamReadyToHelp: 'Our support team is ready to help!',
    pleaseEmail: 'Please email ',
    call: 'call',

    // Billing Address
    billingAddressTitle: 'Billing Address',
    yourBillingAddress: 'Your Billing Address for Online Purchases',
    pleaseUseThis: "Please use this billing address whenever you're making an online transaction.",

    // Error Modal
    whoops: 'Whoops',
    encounteredAnError: "We've encountered an error.",
    tryAgain: 'Try again',
    ifProblemPersists: 'If this problem persists, please reach out to your GiveCard contact',
    forDevelopers: 'For developers',
  },

  es: {
    // Platform Home Page
    for: 'Para',
    cardholders: 'Titulares de la Tarjetas',
    admins: 'Administradores',
    cardholdersLandingSubheading: '¿Recibió una Givecard y necesita más información?',
    adminLandingSubheading: 'Ingrese a su cuenta de Givecard.',

    // Cardholders View
    cardholderWelcome: 'La esperanza comienza aquí',
    cardholderSubWelcome: 'Estamos felices de que haya recibido su GiveCard.',
    memberSubWelcome: 'Estamos felices de que te unas a nosotros.',

    // CardholderLogin Component
    login: 'Identifícate',
    enterAValidEmail: 'Ingrese un correo electrónico válido',
    createAnAccount: 'Crear cuenta',
    or: 'O',
    exploreCard: 'Quieres mas información sobre tu tarjeta?',
    email: 'Email',
    emailOrPhone:  'Email o Número de Telefono',
    password: 'Contraseña',
    welcomeBack: '¡Bienvenido!',
    enterPassword: 'Por favor introducir contraseña',
    enterOneTimePassword: 'Por favor ingrese el código de 6 dígitos',
    codeSentTo: 'Código enviado a',
    passwordReminder: 'Recordatorio. 8 caracteres, 1 número y 1 carácter especial',
    forgotYourPassword: 'te olvidaste tu contraseña?',
    here: 'aqui',
    resetYourPassword: 'Restablecer su contraseña',
    sendResetPasswordLink: 'Enviar enlace de restablecimiento de contraseña',
    resetLinkInstructions: 'Un link para restablecer tu contraseña ',
    checkSpamFolder: 'Si no vez el link, chequear correo spam.',
    loginAgain: 'Ingreso devuelta',
    dontHaveAccount: '¿No tienes una cuenta? Cree una cuenta a través del enlace que se envió a su correo electrónico.',
    verifyPhoneNumber: 'Verifique su número de teléfono',
    sendOTP: 'Enviar código de verificación',
    verifyOTP: 'Verificar código de verificación',
    accountNotActivatedYet: 'Su cuenta aún no ha sido activada. Por favor busque el enlace de activación en su correo electrónico o en los mensajes de su teléfono.',
    noAccountWithPhoneNumber: 'No hay ninguna cuenta asociada con este número de teléfono.',

    // Quick Resources Component Variables
    quickResources: 'Recursos Rápidos',
    checkBalance: 'Consultar Saldo',
    findAnATM: 'Encontrar un Cajero Automático',
    setPin: 'Establecer Pin',
    setPinInfo: 'Este es un código de cuatro dígitos que no debe compartir con nadie.',

    // Frequently Asked Questions
    faqHeader: 'Preguntas frecuentes',
    faqQuestion1: '¿Necesito activar mi tarjeta antes de usarla?',
    faqAnswer1: 'No, no necesitas hacer nada antes de comenzar a usar su tarjeta. Siempre que haya un saldo en su tarjeta, puedes usar la.',
    faqQuestion2: '¿Dónde puedo usar mi nueva tarjeta?',
    faqAnswer2: 'En cualquier lugar que acepte tarjetas de débito, siempre que su programa no tenga reglas de gasto. También se pueden aplicar restricciones en las tiendas que requieren identificación. Si su organización tiene reglas de gasto, se lo informarán antes de entregarle la tarjeta.',
    faqQuestion3: '¿Puedo usar mi tarjeta en un cajero automático para retirar efectivo?',
    faqAnswer3: 'Depende de si su organización permite retiros en cajeros automáticos. Póngase en contacto con su organización para averiguarlo. Si lo hacen, primero debe establecer un PIN. Establezca su PIN haciendo clic en el botón "Establecer PIN" de arriba.',
    faqQuestion4: '¿Cómo configuro mi PIN?',
    faqAnswer4: 'Al hacer clic en el botón "Establecer PIN", puede iniciar el proceso de configuración de su PIN. Tenga su tarjeta a mano. Siga las instrucciones.',
    faqQuestion5: '¿Cómo restablezco mi PIN?',
    faqAnswer5: 'Al hacer clic en el botón "Establecer PIN", puede iniciar el proceso de restablecer su PIN. Tenga su tarjeta a mano. Siga las instrucciones. Aparecerán igual que la primera vez que has establecido un pin.',
    faqQuestion6: 'Perdí mi tarjeta, o alguien me la robó. ¿Qué tengo que hacer?',
    faqAnswer6: 'Comuníquese con el soporte de GiveCard (envíe un correo electrónico o llame al 908-529-0955). GiveCard pasará por el proceso de cancelar la tarjeta y enviarle una nueva.',
    faqQuestion7: 'Mi tarjeta fue rechazada. ¿Por qué?',
    faqAnswer7: 'Hay varias razones por las que una tarjeta puede haber sido rechazada. El problema más común es que no tienes saldo suficiente. Otras razónes incluyen hacer clic en cancelar cuando solicita un PIN, un error en el lector de tarjetas o que sus organizaciones hayan establecido reglas de gasto. Póngase en contacto con su organización para averiguarlo. Otros comerciantes pueden rechazar en función de sus propias reglas de transacción y rechazo. Es posible que pueda resolver este problema comunicándose directamente con el comerciante, y es posible que pueda ofrecer una anulación si confirma que el emisor de su tarjeta aprobó la transacción.',
    faqQuestion8: '¿Qué dirección de facturación debo usar para las compras en línea?',
    faqAnswer8: 'Si tiene una tarjeta física, cree una cuenta y haga clic en "Dirección de Envio" dentro del portal del titular de la tarjeta o comuníquese con el soporte de GiveCard. Si tiene una tarjeta virtual, la dirección de envio es 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: '¿Con quién me comunico en caso de fondos insuficientes?',
    faqAnswer9: 'Póngase en contacto con la organización que le dio la tarjeta. La organización podrá informarle sobre el saldo de su fondo.',

    // Additional Support Footer
    furtherQuestionsInfo: 'Si tiene más preguntas o problemas, comuníquese con ',
    supportEmailAndPhoneNumber: 'o llame al 908-529-0955',
    customerSupportHours: 'El servicio de atención al cliente está disponible de 9 a.m. a 6 p.m. E.S.T de lunes a viernes.*',
    holidaysSupportException: 'Los días festivos afectarán a estos horarios',

    // Card Details
    confirmCardInformation: 'Confirmar información de la tarjeta',
    last6Digits: 'Últimos 6 dígitos',
    organizationName: 'Nombre de la Organización',
    expirationDate: 'Fecha de caducidad',
    invalidError: 'Error inválido',

    // Set Pin
    last6Error: 'Formato de entrada de los últimos 6 dígitos inválida',
    expirationError: 'El formato de entrada de la fecha de caducidad no es válido',
    setNewPin: 'Establecer un nuevo PIN',
    confirmNewPin: 'Confirmar PIN nuevo',
    pinLengthError: 'El pin debe tener 4 dígitos',
    pinMatchingError: 'Los numeros de PIN no coinciden.',
    pinSetSuccess: '¡Éxito!',
    yourNewPinIs: 'Tu nuevo PIN es: ',
    doNotSharePin: 'No comparta su pin con nadie más.',
    pinInstructions: 'Ahora puede usar este pin para cualquier transacción o retiro de efectivo que solicite un pin.',
    alreadySetPin: '¡Parece que su PIN ya ha sido configurado!',
    accountLoginNeededForPinReset: 'Por favor inicie sesión en su cuenta de GiveCard para restablecer su PIN o comuníquese a',
    oops: '¡Uy!',
    expirationDateExample: 'MM/AA',
    yourCardIs: 'Tu tarjeta es',
    cantSetPinWithThisStatus: 'No puedes establecer un PIN en una tarjeta con este estado.',
    reachOutToYourOrgOrGiveCard: 'Comuníquese con su organización o con',
    ifItsAMistake: 'si cree que esto es un error.',

    // Lock Card
    unlockCardPromptHeader: '¿Te gustaría desbloquear esta tarjeta?',
    unlockCardPromptDetails: 'La tarjeta permitirá que las transacciones se reanuden instantáneamente y que se gasten los fondos',
    unlockCardButton: 'Sí, desbloquear esta tarjeta.',
    unlockSuccessful: 'Su tarjeta ha sido desbloqueada con éxito.',
    lockCardPromptHeader: '¿Estás seguro/a de que quieres bloquear esta tarjeta?',
    lockCardPromptDetails: 'La tarjeta ya no podrá realizar transacciones ni acceder a fondos mientras esté bloqueada. Podrás desbloquear esta tarjeta en cualquier momento.',
    lockCardButton: 'Sí, bloquea esta tarjeta.',
    lockSuccessfulHeader: 'Su tarjeta ha sido bloqueada con éxito.',
    lockSuccessfulDetails: 'Esta tarjeta no podrá usar ningún fondo ni completar ninguna transacción a partir de este momento.',
    unlockHowTo: 'Si desea desbloquear esta tarjeta, desplace el cursor sobre el estado en la página de la tarjeta individual y lo dirigirá el proceso de desbloqueo.',

    // Check Balance
    currentBalance: 'Saldo actual',
    yourCardBalance: 'El saldo de tu tarjeta es:',
    contactSupportEmail: 'Si tiene alguna pregunta o problema, comuníquese con support',

    // Create an Account
    createNewAccountSubheading: 'Antes de crear una cuenta, necesitamos confirmar los detalles de su GiveCard. ¡Solo necesitaras hacer esto una vez!',
    wantToKnowMore: '¿Quieres saber más información sobre tu tarjeta?',
    createAPassword: 'Crear una contraseña',
    passwordRequirements: 'Requisitos de la contraseña: 8 caracteres, 1 número y 1 carácter especial',
    confirm: 'Confirmar',
    confirmPassword: 'Confirmar contraseña',
    alreadyHaveAnAccount: '¿Ya tienes una cuenta? Acceso',
    accountAlreadyExistsError: '¡Vaya! Ya existe una cuenta con este correo electrónico.',
    cardAlreadyLinkedError: 'Esta tarjeta ya está vinculada con otra cuenta',
    accountCreationError: 'Error al crear la cuenta.',
    error: 'Error',
    tryAgainMessage: 'Inténtalo de nuevo o ponte en contacto con support',
    passwordMismatchError: 'Las contraseñas deben coincidir.',
    passwordInvalidError: 'La contraseña debe contener por lo menos 8 caracteres y al menos incluir un número y un carácter especial.',
    welcome: '¡Bienvenidos!',
    invalidTokenPage: '¡Disculpe, link incorrecto! Por favor diríjase al link que le hemos enviado a su correo electrónico para crear una cuenta.',
    createAccountToAccessVirtualCard: '¡Cree una cuenta para acceder a su tarjeta virtual!',
    alreadyHaveAccount: '¿Ya tienes una cuenta?',
    firstName: 'Nombre',
    lastName: 'Apellido',
    firstAndLastName: 'Nombre y Apellido',
    phoneNumber: 'Número de Teléfono',
    createAcctToViewPortal: 'Crea una cuenta para ver la información de tu empresa',
    yourEmailAddress: 'Tu correo electrónico',
    haventMetPasswordRequirements: 'No ha cumplido con todos los requisitos de contraseña.',
    createProfile: 'Crear Perfil',
    creatingUser: 'Creando usuario',
    invalidEmail: 'Email inválido',
    invalidPhoneNumber: 'Número de teléfono invalido',
    phoneRequirements: 'Necesita tener 10 dígitos, sin guiones',
    createAccountSubheading: '¡Ingresemos algunos datos básicos!',
    enterMobilePhoneNumber: 'Por favor ingrese su número de teléfono móvil',
    requirements: 'Requisitos:',
    atLeast8Chars: '• Al menos 8 carácteres',
    oneNumber: '• 1 número',
    oneSpecialCharacter: '• 1 caracter especial como',
    passwordsMatch: '• Las contraseñas coinciden',

    // Cardholder Portal
    notificationFeatureAnnouncement: '¡Mira la función de notificaciones! Ahora puede recibir notificaciones por correo electrónico sobre sus transacciones recientes. Haga clic en el botón de notificaciones para obtener más información.',
    cardActivityHistory: 'Historial de actividad de la tarjeta',
    transactions: 'Transacciónes',
    loadHistory: 'Historial de carga',
    date: 'Fecha',
    transaction: 'Transacción',
    merchantCategory: 'Categoría de comerciante',
    amount: 'Cantidad',
    timeLoaded: 'Tiempo de carga',
    amountLoaded: 'Cantidad cargada',
    atm: 'Cajero Automático',
    lockCard: 'Bloquear Tarjeta',
    unLockCard: 'Desbloquear Tarjeta',
    home: 'Inicio',
    emailNotificationsHeader: 'Notificaciones de Correo Electrónico',
    emailNotificationsSubheader1: 'Reciba notificaciones enviadas a su correo electrónico sobre cualquier actividad de la tarjeta.',
    emailNotificationsSubheader2: 'Puede desactivarlos en cualquier momento.',
    notifications: 'Notificaciones',
    recentTransactions: 'Transacciones Recientes',
    recentTransactionsSubheader: 'Reciba notificaciones sobre sus transacciones recientes para realizar un seguimiento de los gastos y evitar fraudes.',
    cardDeclines: 'Tarjeta rechazada',
    cardDeclinesSubheader: 'Reciba notificaciones cuando la transacción sea rechazada, así pueda comprender el motivo de la misma.',
    sendingTo: 'Enviando a: ',
    saving: 'Salvando...',
    save: 'Salvar',
    cardIsFrozen: 'Tu tarjeta ha sido bloqueada por tu organización.',
    cardIsClosed: 'Tu tarjeta fue cerrada por tu organización.',
    contactAdministrator: 'Si tienes alguna pregunta, comunícate con la organización que te proporcionó la tarjeta.',
    cardCanOnlyBeUsedAt: 'Tu programa solo permite que esta tarjeta se use en los siguientes comerciantes:',
    moreInfoAboutMerchantRestrictions: 'Para obtener más información sobre las restricciones de los comerciantes, comuníquese directamente con su programa. GiveCard no es responsable de estas restricciones.',

    // Transaction Table
    pending: 'Pendientes',
    pendingPurchases: 'Compras Pendientes',
    pendingPurchasesDescription: 'Las compras pendientes son cargos que han sido autorizados por el comerciante, pero que aún no se han publicado y, por lo general, se publican en un plazo de 5 días. Están incluidos en su saldo actual',
    cleared: 'Confirmadas',
    clearedPurchases: 'Compras Confirmadas',
    clearedPurchasesDescription: 'Las compras confirmadas han sido autorizadas por el comerciante y están aprobadas.',
    loadingTxs: 'Cargando transacciones',

    // Full Card Details
    errorGettingCardDetails: 'Error al obtener los datos de la tarjeta.',
    pleaseRefreshThePage: 'Por favor actualice la página.',
    cardNumber: 'Número de Tarjeta',
    howDoIUseMyVirtualCard: '¿Cómo uso mi tarjeta virtual?',
    usingAVirtualCard: 'Cómo usar la Tarjeta Virtual',
    clickThe: 'Haz clic en el',
    toSeeFullCardDetails: 'para ver toda la información de la tarjeta',
    cardCanBeUsedAnywhere: 'La tarjeta puede ser usada en cualquier lugar que acepte Mastercard Prepagada',
    cardInstructions: 'Para usar la tarjeta en línea copia y pega la información mostrada en este portal',
    digitalWalletInstructions: 'Agrega tu tarjeta a',
    digitalWalletInstructions2: 'ingresando la información de la tarjeta en tu teléfono. Instrucciones detalladas enlazadas.',
    orLowercase: 'o',
    billingAddress: 'Para compras que requieren dirección de cobro utiliza:',
    needSupport: '¿Necesitas ayuda?',
    teamReadyToHelp: '¡Nuestro equipo de soporte está a tu disposición!',
    pleaseEmail: 'Envía un correo electrónico a',
    call: 'llámanos al ',

    // Billing Address
    billingAddressTitle: 'Dirección de Envio',
    yourBillingAddress: 'Su dirección de facturación para compras en línea',
    pleaseUseThis: 'Utilice esta dirección de facturación siempre que realice una transacción en línea.',

    // Error Modal
    whoops: '¡Uy',
    encounteredAnError: 'Hemos encontrado un error',
    tryAgain: 'Intente de nuevo',
    ifProblemPersists: 'Si este problema persiste, comuníquese con su contacto de GiveCard',
    forDevelopers: 'Para Desarrolladores',
  },

  ru: {
    // Platform Home Page
    for: 'Для',
    cardholders: 'держателей карт',
    admins: 'администраторов',
    cardholdersLandingSubheading: 'Вы получили карту GiveCard и ищете дополнительную информацию?',
    adminLandingSubheading: 'Войдите в панель управления GiveCard!',

    // Cardholders View
    cardholderWelcome: ' Надежда начинается здесь',
    cardholderSubWelcome: ' Мы рады, что Вы получили свою GiveCard!',

    // CardholderLogin Component
    login: ' логин ',
    enterAValidEmail: ' Введите действительный адрес электронной почты',
    or: ' ИЛИ',
    exploreCard: ' Хотите узнать больше о своей карте?',
    createAnAccount: ' создать учетную запись',
    email: ' Электронная почта ',
    emailOrPhone:  'Электронная почта или номер телефона',
    password: ' Пароль',
    welcomeBack: 'Добро пожаловать обратно!',
    enterPassword: ' Пожалуйста, введите пароль',
    enterOneTimePassword: 'Пожалуйста, введите 6-значный код',
    codeSentTo: 'Код отправлен на',
    passwordReminder: ' Напоминание. 6 символов, специальный символ ',
    forgotYourPassword: 'Забыли пароль? Сброс',
    here: ' здесь',
    resetYourPassword: ' Сбросить пароль',
    sendResetPasswordLink: 'Отправить ссылку на сброс пароля',
    resetLinkInstructions: 'Ссылка для восстановления пароля была отправлена на адрес',
    checkSpamFolder: ' Если вы не видите ссылку, проверьте папку со спамом.',
    loginAgain: 'Повторите вход в систему',
    dontHaveAccount: 'У вас нет учетной записи? Пожалуйста, создайте учетную запись по ссылке, которая была отправлена ​​на вашу электронную почту.',
    verifyPhoneNumber: 'Подтвердите свой номер телефона',
    sendOTP: 'Отправить код подтверждения',
    verifyOTP: 'VПроверить проверочный код',

    // QuickResources Component Variables
    quickResources: ' Быстрая информация ',
    checkBalance: ' Проверка баланса ',
    findAnATM: ' Найти банкомат ',
    setPin: ' Установка PIN-кода ',
    setPinInfo: ' Это четырехзначный код, который не следует делиться с кем-либо.',

    // Frequently Asked Questions
    faqHeader: 'часто задаваемые вопросы',
    faqQuestion1: 'Нужно ли активировать карту перед ее использованием??',
    faqAnswer1: ' Нет, до начала использования карты ничего делать не нужно. Пока на карте есть остаток средств, Вы можете пользоваться картой..',
    faqQuestion2: 'Где я могу использовать свою новую карту?',
    faqAnswer2: 'В любом месте, где принимаются дебетовые карты, при условии, что ваша программа не содержит каких-либо правил расходования средств. Ограничения могут действовать в магазинах, где требуется предъявить удостоверение личности. Если в вашей организации существуют правила расходования средств, они сообщат вам об этом до выдачи карты..',
    faqQuestion3: 'Могу ли я использовать свою карту в банкомате для снятия наличных?',
    faqAnswer3: 'Это зависит от того, разрешает ли ваша организация снимать деньги в банкомате. Для выяснения этого вопроса обратитесь в свою организацию. Если да, то сначала необходимо установить PIN-код. Установите PIN-код, нажав на кнопку "Установить PIN-код" выше..',
    faqQuestion4: 'Как установить PIN-код?',
    faqAnswer4: 'Нажав кнопку Set Pin", вы можете начать процесс установки PIN-кода. Держите карту под рукой. Следуйте инструкциям..',
    faqQuestion5: ' Как сбросить свой PIN-код?',
    faqAnswer5: ' Нажав кнопку "Set Pin", вы можете начать процесс сброса PIN-кода. Имейте под рукой свою карту. Следуйте инструкциям. Они будут такими же, как и при первом вводе PIN-кода.',
    faqQuestion6: 'Я потерял свою карту или кто-то ее украл. Что мне делать?',
    faqAnswer6: 'Свяжитесь со службой поддержки GiveCard (по электронной почте или по телефону 908-529-0955). Компания GiveCard выполнит процедуру блокировки карты и вышлет Вам новую..',
    faqQuestion7: 'Мою карту отклонили. Почему?',
    faqAnswer7: 'Существует несколько причин, по которым карта может быть отклонена. Наиболее распространенной является проблема недостаточного баланса. К другим причинам можно отнести нажатие кнопки "Отмена" при запросе пин-кода, ошибку считывающего устройства карты или установленные в вашей организации правила расходования средств. Обратитесь в свою организацию, чтобы выяснить это. Другие торговые организации могут отказывать в оплате на основании собственных правил проведения операций и правил отказа. Возможно, вам удастся решить эту проблему, связавшись непосредственно с продавцом, и он сможет предложить отмену, если вы подтвердите, что эмитент вашей карты одобрил транзакцию.',
    faqQuestion8: 'Какой адрес следует использовать для оплаты покупок в Интернете??',
    faqAnswer8: 'Если у вас есть физическая карта, создайте учетную запись и нажмите «Адрес для выставления счетов» на портале держателей карт или обратитесь в службу поддержки GiveCard. Если у вас есть виртуальная карта, платежный адрес: 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: 'К кому обращаться по поводу недостатка средств?',
    faqAnswer9: ' Обратитесь в организацию, выдавшую Вам карту. Организация сможет проинформировать Вас об остатке средств.',

    // Additional Support Footer
    furtherQuestionsInfo: 'По всем вопросам и проблемам обращайтесь по адресу',
    supportEmailAndPhoneNumber: 'или позвоните по телефону 908-529-0955',
    customerSupportHours: 'Служба поддержки клиентов работает с 9 до 6 часов с понедельника по пятницу*..*',
    holidaysSupportException: '* Праздники будут влиять на эти часы',

    // Card Details
    confirmCardInformation: 'Подтверждение информации о карте',
    last6Digits: 'Последние 6 цифр номера карты',
    organizationName: 'Название организации на обратной стороне карты ',
    expirationDate: ' Срок действия',
    invalidError: ' Неверные данные карты. Попробуйте еще раз',

    // Set Pin
    last6Error: 'Неверный формат ввода последних 6 цифр',
    expirationError: 'Неверный формат ввода даты истечения срока действия',
    setNewPin: 'Установить новый пин',
    confirmNewPin: 'Confirm New Pin',
    pinLengthError: 'Пин должен состоять из 4 цифр.',
    pinMatchingError: 'Штифты не совпадают.',
    pinSetSuccess: 'Успех!',
    yourNewPinIs: 'Ваш новый пин-код:',
    doNotSharePin: 'Не сообщайте свой пин-код никому другому',
    pinInstructions: 'Теперь вы можете использовать этот пин-код для любой операции или снятия средств, при которой запрашивается пин-код.',
    alreadySetPin: 'It looks like your PIN has already been set!',
    accountLoginNeededForPinReset: 'Пожалуйста, войдите в свой аккаунт GiveCard для сброса PIN-кода или обратитесь в службу поддержки по адресу',
    oops: 'ооопсс!',
    expirationDateExample: 'ММ/ГГ',
    yourCardIs: 'Ваша карта',
    cantSetPinWithThisStatus: "На карту с таким статусом невозможно установить пин-код.",
    reachOutToYourOrgOrGiveCard: 'Пожалуйста, свяжитесь с вашей организацией или',
    ifItsAMistake: 'если вы считаете, что это ошибка.',

    // Lock Card
    unlockCardPromptHeader: 'Хотите ли Вы разблокировать эту карту?',
    unlockCardPromptDetails: 'Карта мгновенно позволит возобновить операции и расходовать средства',
    unlockCardButton: 'Да, разблокируйте эту карту',
    unlockSuccessful: 'Ваша карта успешно разблокирована.',

    lockCardPromptHeader: 'Вы уверены, что хотите заблокировать эту карту?',
    lockCardPromptDetails: 'Карта больше не сможет совершать операции и получать доступ к средствам, будучи заблокированной. Вы сможете разблокировать эту карту в любое время.',
    lockCardButton: 'Да, заблокируйте эту карту',
    lockSuccessfulHeader: 'Ваша карта успешно заблокирована.',
    lockSuccessfulDetails: 'С этого момента по данной карте нельзя будет использовать денежные средства и совершать какие-либо операции.',
    unlockHowTo: 'Если вы хотите разблокировать эту карту, наведите курсор на статус на странице отдельной карты, и он автоматически направит вас в процесс разблокировки.',

    // Check Balance
    currentBalance: 'Текущий баланс',
    yourCardBalance: 'Баланс вашей карты составляет:',
    contactSupportEmail: 'По всем вопросам и проблемам обращайтесь в службу поддержки',

    // Create an Account
    createNewAccountSubheading: 'Перед созданием учетной записи нам необходимо подтвердить данные Вашей карты GiveCard. Это нужно сделать только один раз!',
    wantToKnowMore: 'Хотите получить дополнительную информацию о вашей карте?',
    createAPassword: 'Создать пароль',
    passwordRequirements: 'Требования к паролю: 6 символов, 1 специальный символ',
    confirm: 'Подтвердить',
    confirmPassword: 'Подтвердите пароль',
    alreadyHaveAnAccount: 'Уже есть учетная запись? Войти ',
    accountAlreadyExistsError: 'ооопсс! Учетная запись с таким электронным адресом уже существует',
    cardAlreadyLinkedError: 'Эта карта уже связана с другим счетом',
    accountCreationError: 'Ошибка создания учетной записи.',
    error: ' Ошибка',
    tryAgainMessage: 'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки',
    passwordMismatchError: 'Пароли должны совпадать.',
    passwordInvalidError: 'Пароль должен содержать не менее 8 символов, включая хотя бы одну цифру и один специальный символ.',
    welcome: 'Добро пожаловать!',
    invalidTokenPage: 'Извините, неверная ссылка! Пожалуйста, перейдите по ссылке, которую мы отправили вам по электронной почте, чтобы создать аккаунт.',
    createAccountToAccessVirtualCard: 'Создайте аккаунт, чтобы получить доступ к вашей Виртуальной карте!',
    alreadyHaveAccount: 'Уже есть аккаунт?',
    firstName: 'Имя',
    lastName: 'Фамилия',
    firstAndLastName: 'Имя и фамилия',
    phoneNumber: 'Номер телефона',
    createAcctToViewPortal: 'Создайте аккаунт, чтобы просмотреть портал вашей организации.',
    yourEmailAddress: 'Ваш адрес электронной почты',
    haventMetPasswordRequirements: 'Вы не соответствуете всем требованиям к паролю.',
    createProfile: 'Создать профиль',
    creatingUser: 'Создание пользователя',
    invalidEmail: 'Неверный адрес электронной почты',
    invalidPhoneNumber: 'Неверный номер телефона',
    phoneRequirements: 'Должно содержать 10 цифр, без тире',
    createAccountSubheading: 'Давайте соберем немного основной информации!',
    enterMobilePhoneNumber: 'Пожалуйста, введите свой мобильный номер телефона',
    requirements: 'Требования:',
    atLeast8Chars: '• Минимум 8 символов',
    oneNumber: '• 1 цифра',
    oneSpecialCharacter: '• 1 специальный символ как',
    passwordsMatch: '• Пароли совпадают',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'Проверьте функцию уведомлений! Теперь вы можете получать уведомления по электронной почте о ваших недавних транзакциях. Нажмите на кнопку уведомлений, чтобы получить дополнительную информацию.',
    cardActivityHistory: 'История действий по карте',
    transactions: 'Транзакции',
    loadHistory: 'История загрузки',
    date: ' Дата ',
    transaction: 'Транзакция',
    merchantCategory: 'Категория торговца',
    amount: 'Сумма',
    timeLoaded: 'Время загружено',
    amountLoaded: 'Сумма загружена',
    lockCard: ' Карточка замка',
    unlockCard: 'Разблокировать карту',
    home: 'Дом',
    emailNotificationsHeader: 'Уведомления по электронной почте',
    emailNotificationsSubheader1: 'Получайте уведомления на свою электронную почту о любых действиях с картой.',
    emailNotificationsSubheader2: 'Вы можете отключить их в любое время.',
    notifications: 'Уведомления',
    recentTransactions: 'Недавние транзакции',
    recentTransactionsSubheader: 'Получайте уведомления о ваших последних транзакциях, чтобы отслеживать расходы и предотвращать мошенничество.',
    cardDeclines: 'Карта отклонена',
    cardDeclinesSubheader: 'Получайте уведомления, когда ваша карта отказывается понять причину.',
    sendingTo: 'Отправка по адресу: ',
    saving: 'Сохранение...',
    save: 'Сохранять',
    cardIsFrozen: 'Ваша карта была заморожена вашей организацией.',
    cardIsClosed: 'Ваша карта была закрыта вашей организацией.',
    contactAdministrator: 'Если у вас есть вопросы, обратитесь к организации, которая предоставила вам карту.',
    cardCanOnlyBeUsedAt: 'Ваша программа позволяет использовать эту карту только в следующих магазинах:',
    moreInfoAboutMerchantRestrictions: 'Для получения дополнительной информации о ограничениях торговых организаций обратитесь непосредственно в свою программу. GiveCard не несет ответственности за эти ограничения.',

    // Transaction Table
    pending: ' в ожидании',
    pendingPurchases: 'Отложенные покупки',
    pendingPurchasesDescription: 'Отложенные покупки - это платежи, которые были авторизованы продавцом, но еще не оплачены, и обычно оплачиваются в течение 5 дней. Они включаются в текущий баланс.',
    cleared: ' Очищено',
    clearedPurchases: 'Очищенные закупки',
    clearedPurchasesDescription: ' Покупки, прошедшие клиринг, были авторизованы продавцом и одобрены.',
    loadingTxs: 'Загрузка транзакций',

    // Full Card Details
    errorGettingCardDetails: 'Ошибка получения данных карты.',
    pleaseRefreshThePage: 'Пожалуйста, обновите страницу.',
    cardNumber: 'Номер карты',
    howDoIUseMyVirtualCard: 'Как мне использовать виртуальную карту?',
    usingAVirtualCard: 'Использование виртуальной карты',
    clickForDetails: 'Нажмите, чтобы увидеть полную информацию о карте',
    clickThe: 'Нажать на ',
    toSeeFullCardDetails: 'чтобы вы могли видеть полные данные карты',
    cardCanBeUsedAnywhere: 'Эту карту можно использовать везде, где принимается предоплаченная карта Mastercard.',
    cardInstructions: 'Используйте эту карту онлайн, скопировав и вставив данные, отображаемые на этом портале.',
    digitalWalletInstructions: 'Добавьте свою карту в',
    digitalWalletInstructions2: 'введя данные карты на телефоне. Дальнейшие инструкции по ссылке.',
    orLowercase: 'или',
    billingAddress: 'Если для покупки требуется платежный адрес, используйте следующий адрес:',
    needSupport: 'Нужна поддержка?',
    teamReadyToHelp: 'Наша служба поддержки готова помочь!',
    pleaseEmail: 'Пожалуйста, напишите по адресу ',
    call: 'позвоните по телефону ',

    // Billing Address
    billingAddressTitle: 'Адрес для выставления счета',
    yourBillingAddress: 'Ваш платежный адрес для онлайн-покупок',
    pleaseUseThis: 'Пожалуйста, используйте этот платежный адрес каждый раз, когда вы совершаете онлайн-транзакцию.',

    // Error Modal
    whoops: 'ооопсс',
    encounteredAnError: 'мы столкнулись с ошибкой',
    tryAgain: 'Попробуйте еще раз',
    ifProblemPersists: 'Если проблема не исчезнет, обратитесь к своему контактному лицу в GiveCard',
    forDevelopers: 'Для разработчиков программного обеспечения',
  },

  zh: {
    // Platform Home Page
    for: '为',
    cardholders: '持卡人',
    admins: '管理员',
    cardholdersLandingSubheading: '您收到赠卡并正在寻找更多信息吗？',
    adminLandingSubheading: '登录到您的GiveCard仪表板！',
    cardholderWelcome: '希望从这里开始',
    cardholderSubWelcome: '我们很高兴您收到了您的GiveCard。',
    memberSubWelcome: '我们很高兴您加入我们。',

    // CardholderLogin Component
    login: '登录',
    enterAValidEmail: '请输入有效的电子邮件',
    or: '或',
    exploreCard: '想进一步了解您的卡吗？',
    createAnAccount: '创建账户',
    email: '电子邮箱',
    emailOrPhone: '电子邮件或电话号码',
    password: '密码',
    welcomeBack: '欢迎回来！',
    enterPassword: '请输入您的密码',
    enterOneTimePassword: '请输入6位数字代码',
    codeSentTo: '代码发送至',
    passwordReminder: '提醒。8个字符，包括1个数字和1个特殊字符',
    forgotYourPassword: '忘记密码？重设密码',
    here: '这里',
    resetYourPassword: '重设您的密码',
    sendResetPasswordLink: '发送重设密码链接',
    resetLinkInstructions: '重设密码链接已发送至',
    checkSpamFolder: '如果您没有收到链接，请检查您的垃圾邮件文件夹。',
    loginAgain: '重新登录',
    dontHaveAccount: '沒有帳戶？請透過發送到您電子郵件的連結建立一個帳戶。',
    verifyPhoneNumber: '验证您的电话号码',
    sendOTP: '发送验证码',
    verifyOTP: '验证验证码',

    // QuickResources Component Variables
    quickResources: '快速资源',
    checkBalance: '查询余额',
    findAnATM: '查找自动取款机',
    setPin: '设置数字短码',
    setPinInfo: '这是一个四位数的代码，不应与任何人共享。',

    // Frequently Asked Questions
    faqHeader: '常见问题',
    faqQuestion1: '在使用之前是否需要激活我的卡？',
    faqAnswer1: '不需要，在开始使用您的卡之前，您不需要做任何事情。只要您的卡上有余额，您就可以使用您的卡。',
    faqQuestion2: '我可以在哪里使用我的新卡？',
    faqAnswer2: '只要您的计划没有任何消费规定，任何接受借记卡的地方都可以使用。而需要出示身份证件的商店可能会有限制。如果您的计划有消费规定，他们会在分发卡之前告知您。',
    faqQuestion3: '我可以在自动取款机上使用赠卡提取现金吗？',
    faqAnswer3: '这取决于您的计划是否允许从自动取款机提取现金。请联系您的计划了解情况。如果允许，您需要先设置数字短码PIN。',
    faqQuestion4: '如何设置我的数字短码？',
    faqAnswer4: '点击“设置数字短码”按钮，您可以开始设置数字短码。请随身携带您的卡。按照说明操作。',
    faqQuestion5: '如何重置我的数字短码？',
    faqAnswer5: '点击“设置数字短码”按钮，您可以开始重新设置数字短码。请随身携带您的卡。按照说明操作。提示与第一次设置数字短码时的提示相同。',
    faqQuestion6: '如果我的卡丢失或被盗，我该怎么办？',
    faqAnswer6: '请联系GiveCard客服部门（发送电子邮件或致电）。GiveCard将会锁定您的卡并向您发送一张新卡。',
    faqQuestion7: '为什么我的卡被拒绝？',
    faqAnswer7: '卡被拒绝有多种原因。最常见的问题是余额不足。其他问题包括在要求输入密码时点击取消、读卡器出错或您的计划设定了新的消费规定。如果是后一种情况, 请联系您的计划了解情况。',
    faqQuestion8: '在网上购物时应使用哪个账单地址？',
    faqAnswer8: '如果您有实体卡，请创建一个帐户并单击持卡人门户内的“帐单地址”或联系 GiveCard 支持人员。 如果您有虚拟卡，账单地址为 123 GiveCard Way, New York, NY, 10001。',
    faqQuestion9: '如果卡上金额不足，该与谁联系？',
    faqAnswer9: '请联系提供给您卡的组织。该组织会告知您卡上的余额。',

    // Additional Support Footer
    furtherQuestionsInfo: '如有其他疑问或问题，请联系',
    supportEmailAndPhoneNumber: '或致电 908-529-0955',
    customerSupportHours: '客服时间为周一至周五上午9点至下午6点（东部标准时间）。',
    holidaysSupportException: '节假日时间会有改变',

    // Card Details
    confirmCardInformation: '确认卡片信息',
    last6Digits: '卡号最后6位数字',
    organizationName: '卡片背面的组织名称',
    expirationDate: '有效至日期',
    invalidError: '卡片数据无效。请重试',

    // Set Pin
    last6Error: '最后6位数字输入格式无效',
    expirationError: '有效至日期输入格式无效',
    setNewPin: '设置新的数字短码',
    confirmNewPin: '确认新的数字短码',
    pinLengthError: '数字短码长度必须为4位数',
    pinMatchingError: '数字短码不匹配',
    pinSetSuccess: '设置成功！',
    yourNewPinIs: '您的新的数字短码是',
    doNotSharePin: '请勿与他人分享您的数字短码',
    pinInstructions: '您现在可以在任何要求输入数字短码的交易或取款中使用该密码',
    alreadySetPin: '您的数字短码已设置！',
    accountLoginNeededForPinReset: '请登录您的GiveCard账户重置数字短码，或通过以下方式联系支持人员',
    expirationDateExample: '月份/年份(例如: 02/24)',
    yourCardIs: '您的卡是',
    cantSetPinWithThisStatus: '您无法在具有此状态的卡上设置 PIN 码。',
    reachOutToYourOrgOrGiveCard: '请联系您的组织或',
    ifItsAMistake: '如果你认为这是一个错误。',

    // Lock Card
    unlockCardPromptHeader: '您想解锁此卡吗？',
    unlockCardPromptDetails: '该卡将立即恢复交易和支付。',
    unlockCardButton: '是的，解锁此卡。',
    unlockSuccessful: '您的卡已成功解锁。',
    lockCardPromptHeader: '您确定要锁定此卡吗？',
    lockCardPromptDetails: '该卡在锁定状态下将无法进行支付或取款。 您可以随时解锁。',
    lockCardButton: '是的，锁定此卡。',
    lockSuccessfulHeader: '您的卡已成功锁定。',
    lockSuccessfulDetails: '此卡从现在起将无法使用任何资金或完成任何交易。',
    unlockHowTo: '如果您要解锁此卡，请将鼠标悬停在个人卡页面的状态上，系统将自动引导您完成解锁过程。',

    // Check Balance
    currentBalance: '当前余额',
    yourCardBalance: '您的卡余额为：',
    contactSupportEmail: '如有任何疑问或问题，请联系支持部门',

    // Create an Account
    createNewAccountSubheading: '在创建账户之前，我们需要确认您的GiveCard详细信息。您只需完成一次！',
    wantToKnowMore: '想了解有关您卡的更多信息吗？',
    createAPassword: '创建密码',
    passwordRequirements: '密码要求：8个字符，包括1个数字和1个特殊字符',
    confirm: '确认',
    confirmPassword: '确认密码',
    alreadyHaveAnAccount: '已经有账户了？请登录',
    accountAlreadyExistsError: '糟糕！该电子邮件已经存在账户中。',
    cardAlreadyLinkedError: '此卡已与另一个账户关联',
    accountCreationError: '创建账户时出错。',
    error: '错误',
    tryAgainMessage: '请重试，或联系支持',
    passwordMismatchError: '密码必须相匹配。',
    passwordInvalidError: '密码必须包含8个字符，包括至少一个数字和一个特殊字符。',
    welcome: '欢迎！',
    invalidTokenPage: '对不起，链接错误！请点击我们通过电子邮件发送给您的链接以创建账户。',
    createAccountToAccessVirtualCard: '创建账户以访问您的虚拟卡！',
    alreadyHaveAccount: '已经有账户了？',
    firstName: '名字',
    lastName: '姓氏',
    firstAndLastName: '名字和姓氏',
    phoneNumber: '电话号码',
    createAcctToViewPortal: '创建账户以查看您的组织门户。',
    yourEmailAddress: '您的电子邮件地址',
    haventMetPasswordRequirements: '您尚未满足所有密码要求。',
    createProfile: '创建个人资料',
    creatingUser: '创建用户',
    invalidEmail: '无效的电子邮件',
    invalidPhoneNumber: '无效的电话号码',
    phoneRequirements: '需要有 10 位数字，无破折号',
    createAccountSubheading: '让我们收集一些基本信息！',
    enterMobilePhoneNumber: '请输入您的手机号码',
    requirements: '要求:',
    atLeast8Chars: '• 至少 8 个字符',
    oneNumber: '• 1 个数字',
    oneSpecialCharacter: '• 1 个特殊字符如',
    passwordsMatch: '• 密码匹配',

    // Cardholder Portal
    notificationFeatureAnnouncement: '查看通知功能！您现在可以接收有关最新交易的电子邮件通知。单击通知按钮获取更多信息。',
    cardActivityHistory: '卡使用历史',
    transactions: '使用',
    loadHistory: '加载历史记录',
    date: '日期',
    transaction: '交易',
    merchantCategory: '商家类别',
    amount: '金额',
    timeLoaded: '加载时间',
    amountLoaded: '加载金额',
    lockCard: '锁定卡',
    unlockCard: '解锁卡',
    home: '主页',
    emailNotificationsHeader: '电子邮件通知',
    emailNotificationsSubheader1: '获取有关卡使用记录的电子邮件通知',
    emailNotificationsSubheader2: '您可以随时关闭这些通知。',
    notifications: '通知',
    recentTransactions: '最近使用记',
    recentTransactionsSubheader: '接收有关您最近使用的通知，以跟踪支出并防止欺诈。',
    cardDeclines: '卡被拒绝',
    cardDeclinesSubheader: '当您的被卡拒绝时接收通知，以了解原因。',
    sendingTo: '发送至: ',
    saving: '保存中...',
    save: '保存',
    cardIsFrozen: '您的卡已被您的组织冻结。',
    cardIsClosed: '您的卡已被您的组织关闭。',
    contactAdministrator: '如果您有任何问题，请联系提供您卡片的组织。',
    cardCanOnlyBeUsedAt: '您的程序仅允许在以下商户使用此卡：',
    moreInfoAboutMerchantRestrictions: '有关商家限制的更多信息，请直接联系您的计划。GiveCard对这些限制不承担责任。',

    // Transaction Table
    pending: '待处理',
    pendingPurchases: '待处理购买',
    pendingPurchasesDescription: '待处理购买是商家已授权但尚未记账的费用，通常在 5 天内记账。它们包含在您的当前余额中。',
    cleared: '已清算',
    clearedPurchases: '已清算购买',
    clearedPurchasesDescription: '已清算购买已由商家授权并获得批准。',
    loadingTxs: '加载交易中',

    // Full Card Details
    errorGettingCardDetails: '获取卡详细信息时出错。',
    pleaseRefreshThePage: '请刷新页面。',
    cardNumber: '卡号',
    howDoIUseMyVirtualCard: '如何使用我的虚拟卡？',
    usingAVirtualCard: '使用虚拟卡',
    clickThe: '点击',
    toSeeFullCardDetails: '以查看完整的卡详细信息',
    cardCanBeUsedAnywhere: '此卡可在接受预付 Mastercard 的任何地方使用',
    cardInstructions: '通过在此门户中显示的详细信息复制并粘贴，在线使用此卡',
    digitalWalletInstructions: '将您的卡添加到',
    digitalWalletInstructions2: '通过在手机上输入卡详细信息。有关更多说明，请参见链接。',
    orLowercase: '或',
    billingAddress: '如果购买需要邮寄地址，请使用以下地址：',
    needSupport: '需要支持？',
    teamReadyToHelp: '我们的支持团队已准备好提供帮助！',
    pleaseEmail: '请发送电子邮件至 ',
    call: '拨打电话',

    // Billing Address
    billingAddressTitle: '账单地址',
    yourBillingAddress: '您的在线购买的账单地址',
    pleaseUseThis: '在进行在线交易时，请使用此账单地址。',

    // Error modal
    whoops: '哎呀',
    encounteredAnError: '我们遇到了一个错误',
    tryAgain: '再试一次',
    ifProblemPersists: '如果问题仍然存在，请联系您的GiveCard联系人',
    forDevelopers: '对于开发人员',
  },

  uk: {
    // Platform Home Page
    for: 'Для',
    cardholders: 'власників карток',
    admins: 'адміністраторів',
    cardholdersLandingSubheading: 'Отримали GiveCard і шукаєте додаткову інформацію?',
    adminLandingSubheading: 'Увійдіть на інформаційну панель GiveCard!',

    cardholderWelcome: 'Тут починається надія',
    cardholderSubWelcome: 'Ми раді, що ви отримали GiveCard.',
    memberSubWelcome: 'Ми раді, що ви долучаєтесь до нас.',

    // CardholderLogin Component
    login: 'Увійти',
    enterAValidEmail: 'Введіть дійсну електронну адресу',
    or: 'АБО',
    exploreCard: 'Бажаєте дізнатися більше про свою картку?',
    createAnAccount: 'Створити обліковий запис',
    email: 'Електронна адреса',
    emailOrPhone: 'Електронна пошта або номер телефону',
    password: 'Пароль',
    welcomeBack: 'З поверненням!',
    enterPassword: 'Введіть пароль',
    enterOneTimePassword: 'Будь ласка, введіть 6-значний код',
    codeSentTo: 'Код надіслано',
    passwordReminder: 'Нагадування. 8 символів, включно з 1 цифрою та 1 спеціальним символом',
    forgotYourPassword: 'Забули пароль? Скиньте його',
    here: 'тут',
    resetYourPassword: 'Скинути пароль',
    sendResetPasswordLink: 'Надіслати посилання для скидання пароля',
    resetLinkInstructions: 'Посилання для скидання пароля надіслано на адресу',
    checkSpamFolder: 'Якщо не бачите посилання, перевірте папку зі спамом.',
    loginAgain: 'Увійти знову',
    dontHaveAccount: 'Немає облікового запису? Будь ласка, створіть обліковий запис за посиланням, надісланим на вашу електронну пошту.',
    verifyPhoneNumber: 'Підтвердьте свій номер телефону',
    sendOTP: 'Надіслати код підтвердження',
    verifyOTP: 'Перевірте код підтвердження',

    // QuickResources Component Variables
    quickResources: 'Швидкі ресурси',
    checkBalance: 'Перевірити баланс',
    findAnATM: 'Знайти банкомат',
    setPin: 'Задати PIN-код',
    setPinInfo: 'Це чотиризначний код, яким не слід ділитися з ким-небудь.',

    // Frequently Asked Questions
    faqHeader: 'Поширені запитання',
    faqQuestion1: 'Чи потрібно активувати картку перед її використанням?',
    faqAnswer1: 'Ні, щоб почати користуватися карткою, вам не треба нічого робити. Якщо на картці є баланс, ви вже можете нею користуватися.',
    faqQuestion2: 'Де я можу скористатися своєю новою карткою?',
    faqAnswer2: 'У будь-якому місці, де приймають дебетові картки, якщо у вашій програмі немає правил витрат. Обмеження також можуть застосовуватися до закладів, які вимагають посвідчення особи. Якщо у вашій організації встановлено правила витрат, вони повідомлять вам про це перед виданням картки.',
    faqQuestion3: 'Чи можу я використовувати картку в банкоматі для зняття готівки?',
    faqAnswer3: 'Це залежить від того, чи дозволяє ваша організація знімати кошти в банкоматі. Зв’яжіться зі своєю організацією, щоб дізнатися більше. Якщо це так, спочатку потрібно задати PIN-код. Задайте PIN-код, натиснувши кнопку вище «Задати PIN-код».',
    faqQuestion4: 'Як задати PIN-код?',
    faqAnswer4: 'Натиснувши кнопку «Задати PIN-код», ви можете розпочати процес задавання PIN-коду. Тримайте картку під рукою. Дотримуйтесь інструкцій.',
    faqQuestion5: 'Як скинути PIN-код?',
    faqAnswer5: 'Натиснувши кнопку «Задати PIN-код», ви можете розпочати процес скидання PIN-коду. Тримайте картку під рукою. Дотримуйтесь інструкцій. Вони з’являться так само, як і під час першого задавання PIN-коду.',
    faqQuestion6: 'Я загубив свою картку, або хтось її вкрав. Що мені робити? ',
    faqAnswer6: 'Зверніться до служби підтримки GiveCard (на зазначену нижче електронну пошту або зателефонуйте за номером 908-529-0955). GiveCard заблокує картку й надішле вам нову.',
    faqQuestion7: 'Мою картку відхилено. Чому? ',
    faqAnswer7: 'Існує кілька причин, чому картку могли відхилити. Найпоширенішою проблемою є відсутність достатнього балансу. Інші проблеми охоплюють натискання «Скасувати», коли запитується PIN-код, помилку зчитувача карток або якщо ваші організації встановили правила витрат. Зв’яжіться зі своєю організацією, щоб дізнатися більше. Інші заклади можуть відхиляти картки на основі власних правил транзакцій і відхилень. Ви можете вирішити цю проблему, звернувшись безпосередньо до закладу, і він зможе запропонувати заміну, якщо ви підтвердите, що емітент вашої картки схвалив транзакцію.',
    faqQuestion8: 'Яку платіжну адресу слід використовувати для онлайн-покупок?',
    faqAnswer8: 'Якщо у вас є фізична картка, створіть обліковий запис і натисніть «Платіжна адреса» на порталі власника картки або зверніться до служби підтримки GiveCard. Якщо у вас є віртуальна картка, платіжна адреса: 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: 'До кого звертатися щодо недостатності коштів?',
    faqAnswer9: 'Зверніться до організації, яка видала вам картку. Організація зможе повідомити вам про залишок коштів.',

    // Additional Support Footer
    furtherQuestionsInfo: 'З додатковими запитаннями або проблемами звертайтеся за адресою',
    supportEmailAndPhoneNumber: 'або зателефонуйте за номером 908-529-0955',
    customerSupportHours: 'Підтримка клієнтів доступна з 09:00 до 18:00 за північноамериканським східним часом з понеділка по п’ятницю.*',
    holidaysSupportException: '*Під час свят години будуть інші',

    // Card Details
    confirmCardInformation: 'Підтвердити реквізити картки',
    last6Digits: 'Останні 6 цифр номера картки',
    organizationName: 'Назва організації на зворотному боці картки',
    expirationDate: 'Дата закінчення строку дії',
    invalidError: 'Недійсні реквізити картки. Повторіть спробу',

    // Set Pin
    last6Error: 'Неприпустимий формат введення останніх 6 цифр',
    expirationError: 'Недійсний формат введення дати закінчення строку дії',
    setNewPin: 'Задати новий PIN-код',
    confirmNewPin: 'Підтвердити новий PIN-код',
    pinLengthError: 'PIN-код має містити 4 цифри.',
    pinMatchingError: 'PIN-коди не збігаються.',
    pinSetSuccess: 'Успішно!',
    yourNewPinIs: 'Ваш новий PIN-код:',
    doNotSharePin: 'Нікому не надавайте свій PIN-код.',
    pinInstructions: 'Тепер ви можете використовувати цей PIN-код для будь-яких транзакцій або зняття коштів, які вимагають PIN-код.',
    alreadySetPin: 'Схоже, ваш PIN-код уже задано!',
    accountLoginNeededForPinReset: 'Увійдіть у свій обліковий запис GiveCard, щоб скинути PIN-код, або зверніться до служби підтримки за адресою',
    oops: 'Ой!',
    expirationDateExample: 'ММ.РР',
    yourCardIs: 'Ваша картка',
    cantSetPinWithThisStatus: 'Ви не можете встановити пін-код на картку з таким статусом.',
    reachOutToYourOrgOrGiveCard: 'Будь ласка, зверніться до своєї організації або до',
    ifItsAMistake: 'якщо ви вважаєте, що це помилка.',

    // Lock Card
    unlockCardPromptHeader: 'Бажаєте розблокувати цю картку?',
    unlockCardPromptDetails: 'Картка миттєво дозволить відновити транзакції та витрачати кошти.',
    unlockCardButton: 'Так, розблокувати цю картку.',
    unlockSuccessful: 'Вашу картку успішно розблоковано.',

    lockCardPromptHeader: 'Справді заблокувати цю картку?',
    lockCardPromptDetails: 'Якщо картку буде заблоковано, вона більше не зможе здійснювати транзакції та отримувати доступ до коштів. Ви можете розблокувати її в будь-який момент.',
    lockCardButton: 'Так, заблокувати цю картку.',
    lockSuccessfulHeader: 'Вашу картку успішно заблоковано.',
    lockSuccessfulDetails: 'Ця картка на цю мить не зможе використовувати будь-які кошти або виконувати будь-які транзакції.',
    unlockHowTo: 'Якщо хочете розблокувати цю картку, наведіть курсор на статус на окремій сторінці картки, і це автоматично проведе вас через процес розблокування.',

    // Check Balance
    currentBalance: 'Поточний баланс',
    yourCardBalance: 'Баланс вашої картки:',
    contactSupportEmail: 'Щодо будь-яких питань або проблем звертайтеся до служби підтримки',

    // Create an Account
    createNewAccountSubheading: 'Перш ніж створити обліковий запис, нам потрібно підтвердити реквізити вашої GiveCard. Вам треба буде зробити це лише один раз!',
    wantToKnowMore: 'Бажаєте дізнатися більше про свою картку?',
    createAPassword: 'Створити пароль',
    passwordRequirements: 'Вимоги до пароля: 8 символів, включно з 1 цифрою та 1 спеціальним символом',
    confirm: 'Підтвердити',
    confirmPassword: 'Підтвердити пароль',
    alreadyHaveAnAccount: 'Уже маєте обліковий запис? Увійти ',
    accountAlreadyExistsError: 'Ой! Обліковий запис із цією електронною адресою вже існує.',
    cardAlreadyLinkedError: 'Ця картка вже пов’язана з іншим обліковим записом',
    accountCreationError: 'Помилка створення облікового запису.',
    error: 'Помилка',
    tryAgainMessage: 'Спробуйте ще раз або зв’яжіться зі службою підтримки ',
    passwordMismatchError: 'Паролі повинні збігатися.',
    passwordInvalidError: 'Пароль повинен містити 8 символів, зокрема принаймні одну цифру та один спеціальний символ.',
    welcome: 'Ласкаво просимо!',
    invalidTokenPage: 'Вибачте, неправильне посилання! Натисніть на посилання, яке ми надіслали вам в електронному листі, щоб створити обліковий запис.',
    createAccountToAccessVirtualCard: 'Створіть обліковий запис для доступу до своєї віртуальної картки!',
    alreadyHaveAccount: 'Уже маєте обліковий запис?',
    firstName: 'Ім’я',
    lastName: 'Прізвище',
    firstAndLastName: 'Ім’я та прізвище',
    phoneNumber: 'Номер телефону',
    createAcctToViewPortal: 'Створити обліковий запис для перегляду порталу вашої організації.',
    yourEmailAddress: 'Ваша електронна адреса',
    haventMetPasswordRequirements: 'Ви не виконали всі вимоги до пароля.',
    createProfile: 'Створити профіль',
    creatingUser: 'Створення користувача',
    invalidEmail: 'Недійсна електронна адреса',
    invalidPhoneNumber: 'Недійсний номер телефону',
    phoneRequirements: 'Має містити 10 цифр, без тире',
    createAccountSubheading: 'Зберіть основну інформацію!',
    enterMobilePhoneNumber: 'Введіть номер мобільного телефону',
    requirements: 'Вимоги:',
    atLeast8Chars: '• Принаймні 8 символів',
    oneNumber: '• 1 число',
    oneSpecialCharacter: '• 1 спеціальний символ як',
    passwordsMatch: '• Паролі збігаються',
    alreadyHaveAdminAccount: 'Уже маєте обліковий запис адміністратора GiveCard?',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'Перегляньте функцію сповіщень! Тепер ви можете отримувати сповіщення про нещодавні транзакції електронною поштою. Натисніть кнопку сповіщень, щоб дізнатися більше.',
    cardActivityHistory: 'Історія транзакцій по картці',
    transactions: 'Транзакції',
    loadHistory: 'Історія поповнень',
    date: 'Дата',
    transaction: 'Транзакція',
    merchantCategory: 'Категорія продавця',
    amount: 'Сума',
    timeLoaded: 'Час поповнення',
    amountLoaded: 'Сума поповнення',
    lockCard: 'Заблокувати картку',
    unlockCard: 'Розблокувати картку',
    home: 'На головну',
    emailNotificationsHeader: 'Сповіщення електронною поштою',
    emailNotificationsSubheader1: 'Отримувати сповіщення на електронну пошту про будь-які дії з карткою.',
    emailNotificationsSubheader2: 'Ви можете вимкнути їх у будь-який час.',
    notifications: 'Повідомлення',
    recentTransactions: 'Останні транзакції',
    recentTransactionsSubheader: 'Отримувати сповіщення про нещодавні транзакції, щоб відстежувати витрати й запобігти шахрайству.',
    cardDeclines: 'Відхилення картки',
    cardDeclinesSubheader: 'Отримувати сповіщення, коли мою картку відхиляють, щоб зрозуміти причину.',
    cardLoads: 'Поповнення картки',
    cardLoadsSubheader: 'Отримувати сповіщення про надходження коштів на мою картку.',
    sendingTo: 'Надіслати:',
    saving: 'Збереження...',
    save: 'Зберегти',
    cardCanOnlyBeUsedAt: 'Ваш план дозволяє використовувати цю картку лише в таких місцях:',
    moreInfoAboutMerchantRestrictions: 'Щоб дізнатися більше про обмеження, зв’яжіться безпосередньо зі своєю програмою. GiveCard не несе відповідальності за ці обмеження.',

    // Transaction Table
    pending: 'В очікуванні',
    pendingPurchases: 'Покупки в очікуванні',
    pendingPurchasesDescription: 'Покупки в очікуванні — це платежі, авторизовані закладом, але ще не опубліковані, і які зазвичай публікуються протягом 5 днів. Вони включені до вашого поточного балансу.',
    cleared: 'Дата та час',
    cleararedPurchases: 'Оплачені покупки',
    cleararedPurchasesDescription: 'Оплачені покупки авторизовано продавцем і схвалено.',
    loadingTxs: 'Транзакції з поповнення',

    // Full Card Details
    errorGettingCardDetails: 'Помилка отримання реквізитів картки.',
    pleaseRefreshThePage: 'Оновіть сторінку.',
    cardNumber: 'Номер картки',
    howDoIUseMyVirtualCard: 'Як використовувати віртуальну картку?',
    usingAVirtualCard: 'Як використовувати віртуальну картку',
    clickThe: 'натисніть',
    toSeeFullCardDetails: ', щоб переглянути всі реквізити картки',
    cardCanBeUsedAnywhere: 'Цю картку можна використовувати в будь-якому місці, де приймається передплачена картка Mastercard',
    cardInstructions: 'Скористайтеся цією карткою онлайн, скопіювавши й вставивши дані, що відображаються на цьому порталі',
    digitalWalletInstructions: 'Додайте свою картку до',
    digitalWalletInstructions2: 'шляхом введення реквізитів картки на телефоні. Подальші інструкції додаються.',
    orLowercase: 'або',
    billingAddress: 'Якщо покупка вимагає платіжної адреси, використовуйте таку адресу:',
    needSupport: 'Потрібна підтримка?',
    teamReadyToHelp: 'Наша служба підтримки готова допомогти!',
    pleaseEmail: 'Надішліть електронного листа на адресу',
    call: 'зателефонуйте за номером',

    // Billing Address
    billingAddressTitle: 'Платіжна адреса',
    yourBillingAddress: 'Ваша платіжна адреса для онлайн-покупок',
    pleaseUseThis: 'Використовуйте цю платіжну адресу під час здійснення онлайн-транзакцій.',

    // Error Modal
    whoops: 'Ой',
    encounteredAnError: 'Сталася помилка',
    tryAgain: 'спробуйте ще раз',
    ifProblemPersists: 'Якщо проблема не зникне, зверніться до контактної особи GiveCard',
    forDevelopers: 'для розробників',
  },

  fr: {
    // Platform Home Page
    for: 'Pour les',
    cardholders: 'Titulaires de cartes',
    admins: 'Administrateurs',
    cardholdersLandingSubheading: 'Vous avez reçu une GiveCard et cherchez plus d’informations ?',
    adminLandingSubheading: 'Connectez-vous à votre tableau de bord GiveCard !',

    cardholderWelcome: 'L’espoir commence ici',
    cardholderSubWelcome: 'Nous sommes revis que vous ayez reçu votre GiveCard.',
    memberSubWelcome: 'Nous sommes ravis que vous nous rejoigniez.',

    // CardholderLogin Component
    login: 'Se connecter',
    enterAValidEmail: 'Entrer un e-mail valide',
    or: 'OU',
    exploreCard: 'Vous voulez en savoir plus sur votre carte ?',
    createAnAccount: 'Créer un compte',
    email: 'E-mail',
    emailOrPhone: 'E-mail ou numéro de téléphone',
    password: 'Mot de passe',
    welcomeBack: 'Bon retour !',
    enterPassword: 'Entrez votre mot de passe',
    enterOneTimePassword: 'Veuillez saisir le code à 6 chiffres',
    codeSentTo: 'Code envoyé à',
    passwordReminder: 'Rappel. 8 caractères dont 1 chiffre et 1 caractère spécial',
    forgotYourPassword: 'Oublié votre mot de passe ? Réinitialisez-le',
    here: 'ici',
    resetYourPassword: 'Réinitialisez votre mot de passe',
    sendResetPasswordLink: 'Envoyer le lien de réinitialisation du mot de passe',
    resetLinkInstructions: 'Un lien pour réinitialiser votre mot de passe a été envoyé à ',
    checkSpamFolder: 'Si vous ne voyez pas le lien, consultez votre dossier spam.',
    loginAgain: 'Connectez-vous à nouveau',
    dontHaveAccount: "Vous n'avez pas de compte ? Veuillez créer un compte via le lien qui a été envoyé à votre email.",
    verifyPhoneNumber: 'Vérifiez votre numéro de téléphone',
    sendOTP: 'Envoyer le code de vérification',
    verifyOTP: 'Vérifier le code de vérification',

    // QuickResources Component Variables
    quickResources: 'Ressources rapides',
    checkBalance: 'Consulter le solde',
    findAnATM: 'Trouver un distributeur automatique',
    setPin: 'Définir le code PIN',
    setPinInfo: 'Il s’agit d’un code à 4 chiffres que vous ne devez pas partager avec qui que ce soit.',

    // Frequently Asked Questions
    faqHeader: 'Questions fréquemment posées',
    faqQuestion1: 'Dois-je activer ma carte avant de l\'utiliser?',
    faqAnswer1: 'Non, vous n’avez rien à faire avant de commencer à utiliser votre carte. Tant qu\'il y a un solde sur votre carte, vous pouvez utiliser votre carte.',
    faqQuestion2: 'Où puis-je utiliser ma nouvelle carte?',
    faqAnswer2: 'Partout où les cartes de débit sont acceptées, à condition que votre programme n\'ait pas de règles en matière de dépenses. Des restrictions peuvent également s\'appliquer dans les magasins qui nécessitent une pièce d\'identité. Si votre organisation a des règles en matière de dépenses, elle vous le dira avant de vous distribuer la carte.',
    faqQuestion3: 'Puis-je utiliser ma carte à un distributeur automatique pour retirer de l\'argent?',
    faqAnswer3: 'Cela dépend si votre organisation autorise les retraits aux distributeurs automatiques. Veuillez contacter votre organisation pour le savoir. Si tel est le cas, vous devez d’abord définir un code PIN. Définissez votre code PIN en cliquant sur le bouton « Définir le code PIN » ci-dessus.',
    faqQuestion4: 'Comment définir mon code PIN?',
    faqAnswer4: 'En cliquant sur le bouton Définir le code PIN, vous pouvez démarrer le processus de configuration de votre code PIN. Ayez votre carte à portée de main. Suivez les instructions.',
    faqQuestion5: 'Comment réinitialiser mon code PIN ?',
    faqAnswer5: 'En cliquant sur le bouton Définir le code PIN, vous pouvez démarrer le processus de réinitialisation de votre code PIN. Ayez votre carte à portée de main. Suivez les instructions. Elles apparaîtront comme la première fois que vous définissez un PIN.',
    faqQuestion6: 'J\'ai perdu ma carte ou quelqu\'un l\'a volée. Que dois-je faire?',
    faqAnswer6: 'Contactez l\'assistance GiveCard (e-mail ci-dessous, appelez au 908-529-0955). GiveCard passera par le processus de verrouillage de la carte et vous en enverra une nouvelle.',
    faqQuestion7: 'Ma carte a été refusée. Pourquoi?',
    faqAnswer7: 'Il existe plusieurs raisons pour lesquelles une carte peut avoir été refusée. Le problème le plus courant est celui d’un solde insuffisant. Parmi les autres problèmes, il y a le fait de cliquer sur Annuler lorsque le système demande un code PIN, une erreur du lecteur de carte ou le fait que votre organisation a établi des règles en matière de dépenses. Contactez votre organisation pour le savoir. D\'autres commerçants peuvent refuser en fonction de leurs propres règles en matière de transactions et de refus. Vous pourrez peut-être résoudre ce problème en contactant directement le commerçant, et celui-ci pourra peut-être proposer un contournement si vous confirmez que l\'émetteur de votre carte a approuvé la transaction.',
    faqQuestion8: 'Quelle adresse de facturation dois-je utiliser pour les achats en ligne?',
    faqAnswer8: 'Si vous possédez une carte physique, veuillez créer un compte et cliquer sur Adresse de facturation dans le portail des titulaires de carte ou contacter l\'assistance GiveCard. Si vous avez une carte virtuelle, l\'adresse de facturation est 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: 'Qui dois-je contacter en cas de fonds insuffisants?',
    faqAnswer9: 'Contactez l\'organisation qui vous a remis la carte. L\'organisation pourra vous informer du solde de vos fonds.',

    // Additional Support Footer
    furtherQuestionsInfo: 'Pour d\'autres questions ou problèmes, veuillez contacter ',
    supportEmailAndPhoneNumber: 'ou appelez le 908-529-0955',
    customerSupportHours: 'Le support client est disponible de 9h à 18h heure de New York du lundi au vendredi.*',
    holidaysSupportException: '*Les jours fériés affecteront ces horaires',

    // Card Details
    confirmCardInformation: 'Confirmer les données de la carte',
    last6Digits: '6 derniers chiffres du numéro de la carte',
    organizationName: 'Nom de l\'organisation au dos de la carte',
    expirationDate: 'Date d\'expiration',
    invalidError: 'Données de carte invalides. Réessayer',

    // Set Pin
    last6Error: 'Le format de saisie des 6 derniers chiffres est invalide',
    expirationError: 'La format de saisie de la date d\'expiration est invalide',
    setNewPin: 'Définir un nouveau PIN',
    confirmNewPin: 'Confirmer le nouveau PIN',
    pinLengthError: 'Le PIN doit comporter 4 chiffres.',
    pinMatchingError: 'Les PIN ne correspondent pas.',
    pinSetSuccess: 'Succès!',
    yourNewPinIs: 'Votre nouveau code PIN est:',
    doNotSharePin: 'Ne partagez votre code PIN avec personne d’autre.',
    pinInstructions: 'Vous pouvez désormais utiliser ce code PIN pour toute transaction ou retrait nécessitant un code PIN.',
    alreadySetPin: 'Il semble que votre code PIN ait déjà été défini!',
    accountLoginNeededForPinReset: 'Veuillez vous connecter à votre compte GiveCard pour réinitialiser votre code PIN ou contacter l\'assistance via',
    oops: 'Oups!',
    expirationDateExample: 'MM/AA',
    yourCardIs: 'Votre carte est',
    cantSetPinWithThisStatus: 'Vous ne pouvez pas définir de code PIN sur une carte ayant ce statut.',
    reachOutToYourOrgOrGiveCard: 'Veuillez contacter votre organisation ou',
    ifItsAMistake: "si vous pensez que c'est une erreur.",

    // Lock Card
    unlockCardPromptHeader: 'Voulez-vous déverrouiller cette carte?',
    unlockCardPromptDetails: 'La carte permettra instantanément la reprise des transactions et la dépense des fonds.',
    unlockCardButton: 'Oui, déverrouiller cette carte.',
    unlockSuccessful: 'Votre carte a été déverrouillée avec succès.',

    lockCardPromptHeader: 'Êtes-vous sûr de vouloir verrouiller cette carte?',
    lockCardPromptDetails: 'La carte ne pourra plus effectuer de transactions ni accéder aux fonds lorsqu\'elle est verrouillée. Vous pouvez le débloquer à tout moment.',
    lockCardButton: 'Oui, verrouiller cette carte.',
    lockSuccessfulHeader: 'Votre carte a été verrouillée avec succès.',
    lockSuccessfulDetails: 'Cette carte ne pourra plus utiliser de fonds ni effectuer de transactions à partir de ce moment.',
    unlockHowTo: 'Si vous voulez déverrouiller cette carte, survolez le statut sur la page de la carte et elle vous dirigera automatiquement tout au long du processus de déverrouillage.',

    // Check Balance
    currentBalance: 'Solde actuel',
    yourCardBalance: 'Le solde de votre carte est:',
    contactSupportEmail: 'Pour toute question ou problème, veuillez contacter le support',

    // Create an Account
    createNewAccountSubheading: 'Avant de créer un compte, nous devons confirmer les données de votre GiveCard. Vous n\'aurez besoin de le faire qu\'une seule fois!',
    wantToKnowMore: 'Vous voulez en savoir plus sur votre carte?',
    createAPassword: 'Créer un mot de passe',
    passwordRequirements: 'Exigences relatives au mot de passe: 8 caractères, dont 1 chiffre et 1 caractère spécial',
    confirm: 'Confirmer',
    confirmPassword: 'Confirmer le mot de passe',
    alreadyHaveAnAccount: 'Vous avez déjà un compte ? Se connecter ',
    accountAlreadyExistsError: 'Oups ! Un compte avec cette adresse e-mail existe déjà.',
    cardAlreadyLinkedError: 'Cette carte est déjà liée à un autre compte',
    accountCreationError: 'Erreur lors de la création du compte.',
    error: 'Erreur',
    tryAgainMessage: 'Veuillez réessayer ou contacter le support',
    passwordMismatchError: 'Les mots de passe doivent correspondre.',
    passwordInvalidError: 'Le mot de passe doit contenir 8 caractères, dont au moins un chiffre et un caractère spécial.',
    welcome: 'Bienvenue!',
    invalidTokenPage: 'Désolé, lien incorrect! Veuillez cliquer sur le lien que nous vous avons envoyé dans l\'e-mail pour créer un compte.',
    createAccountToAccessVirtualCard: 'Créez un compte pour accéder à votre Carte Virtuelle !',
    alreadyHaveAccount: 'Vous avez déjà un compte?',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    firstAndLastName: 'Nom et prénom',
    phoneNumber: 'Numéro de téléphone',
    createAcctToViewPortal: 'Créez un compte pour afficher le portail de votre organisation.',
    yourEmailAddress: 'Votre adresse e-mail',
    haventMetPasswordRequirements: 'Vous n\'avez pas rempli toutes les exigences relatives au mot de passe.',
    createProfile: 'Créer un profil',
    creatingUser: 'Créer un utilisateur',
    invalidEmail: 'E-mail invalide',
    invalidPhoneNumber: 'Numéro de téléphone invalide',
    phoneRequirements: 'Doit contenir 10 chiffres, sans tirets',
    createAccountSubheading: 'Commençons par quelques informations de base !',
    enterMobilePhoneNumber: 'Veuillez entrer votre numéro de téléphone portable',
    requirements: 'Exigences :',
    atLeast8Chars: '• Au moins 8 caractères',
    oneNumber: '• 1 nombre',
    oneSpecialCharacter: '• 1 caractère spécial comme',
    passwordsMatch: '• Les mots de passe correspondent',
    alreadyHaveAdminAccount: 'Vous avez déjà un compte administrateur GiveCard ?',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'Découvrez la fonction notifications ! Vous pouvez désormais recevoir des notifications par e-mail sur vos transactions récentes. Cliquez sur le bouton des notifications pour plus d\'informations.',
    cardActivityHistory: 'Historique d\'activité de la carte',
    transactions: 'Transactions',
    loadHistory: 'Historique de chargement',
    date: 'Date',
    transaction: 'Transaction',
    merchantCategory: 'Catégorie de commerçant',
    amount: 'Montant',
    timeLoaded: 'Temps chargé',
    amountLoaded: 'Montant chargé',
    lockCard: 'Verrouiller la carte',
    unlockCard: 'Déverrouiller la carte',
    home: 'Accueil',
    emailNotificationsHeader: 'Notifications par e-mail',
    emailNotificationsSubheader1: 'Recevez des notifications envoyées sur votre e-mail sur toute activité de carte.',
    emailNotificationsSubheader2: 'Vous pouvez les désactiver à tout moment.',
    notifications: 'Notifications',
    recentTransactions: 'Transactions récentes',
    recentTransactionsSubheader: 'Recevez des notifications sur vos transactions récentes pour suivre vos dépenses et empêcher la fraude.',
    cardDeclines: 'Refus de carte',
    cardDeclinesSubheader: 'Recevez une notification lorsque votre carte est refusée afin de comprendre pourquoi',
    cardLoads: 'Chargements de carte',
    cardLoadsSubheader: 'Recevez une notification lorsque des fonds sont ajoutés à votre carte.',
    sendingTo: 'Envoi à: ',
    saving: 'Enregistrement...',
    save: 'Enregistrer',
    cardCanOnlyBeUsedAt: `Votre programme permet uniquement d'utiliser cette carte chez les commerçants suivants:`,
    moreInfoAboutMerchantRestrictions: `Pour plus d’informations sur les restrictions des commerçants, veuillez contacter directement votre programme. GiveCard n'est pas responsable de ces restrictions.`,

    // Transaction Table
    pending: 'En attente',
    pendingPurchases: 'Achats en attente',
    pendingPurchasesDescription: 'Les achats en attente sont des frais qui ont été autorisés par le commerçant, mais qui n\'ont pas encore été comptabilisés et qui sont généralement comptabilisés dans les 5 jours. Ils sont inclus dans votre solde actuel.',
    cleared: 'Validé',
    clearedPurchases: 'Achats validés',
    clearedPurchasesDescription: 'Les achats validés ont été autorisés par le commerçant et sont approuvés',
    loadingTxs: 'Chargement des transactions',

    // Full Card Details
    errorGettingCardDetails: 'Erreur lors de l\'obtention des données de la carte.',
    pleaseRefreshThePage: 'Veuillez actualiser la page.',
    cardNumber: 'Numéro de carte',
    howDoIUseMyVirtualCard: 'Comment utiliser ma carte virtuelle ?',
    usingAVirtualCard: 'Utiliser une carte virtuelle',
    clickThe: 'Cliquez sur',
    toSeeFullCardDetails: 'pour voir les données complètes de la carte',
    cardCanBeUsedAnywhere: 'Cette carte peut être utilisée à tout endroit qui accepte une Mastercard prépayée',
    cardInstructions: 'Utilisez cette carte en ligne en copiant et collant les données affichées sur ce portail',
    digitalWalletInstructions: 'Ajoutez votre carte à',
    digitalWalletInstructions2: 'en saisissant les données de la carte sur votre téléphone. De plus amples instructions figurent sur le lien.',
    orLowercase: 'ou',
    billingAddress: 'Si un achat nécessite une adresse de facturation, veuillez utiliser ce qui suit :',
    needSupport: 'Besoin d’aide?',
    teamReadyToHelp: 'Notre équipe de support est prête à vous aider!',
    pleaseEmail: 'Veuillez envoyer un e-mail ',
    call: 'appeler',

    // Billing Address
    billingAddressTitle: 'Adresse de facturation',
    yourBillingAddress: 'Votre adresse de facturation pour les achats en ligne',
    pleaseUseThis: 'Veuillez utiliser cette adresse de facturation chaque fois que vous effectuez une transaction en ligne.',

    // Error Modal
    whoops: 'Oups',
    encounteredAnError: 'Nous avons rencontré une erreur',
    tryAgain: 'Réessayer',
    ifProblemPersists: 'Si ce problème persiste, veuillez contacter votre contact GiveCard.',
    forDevelopers: 'Pour les développeurs',
  },

  sw: {
    // Platform Home Page
    for: 'Kwa ajili ya',
    cardholders: 'Wamiliki wa kadi',
    admins: 'Wasimamizi',
    cardholdersLandingSubheading: 'Je, umepokea kadi ya GiveCard na unatafuta taarifa zaidi?',
    adminLandingSubheading: 'Ingia katika Dashibodi yako ya GiveCard!',

    cardholderWelcome: 'Matumaini Yako Yanaanzia Hapa',
    cardholderSubWelcome: 'Tuna furaha umepokea kadi yako ya GiveCard.',
    memberSubWelcome: 'Tuna furahi kuwa unajiunga na sisi.',

    // CardholderLogin Component
    login: 'Ingia',
    enterAValidEmail: 'Ingiza barua pepe sahihi',
    or: 'AU',
    exploreCard: 'Je, unataka kufahamu zaidi kuhusu kadi yako?',
    createAnAccount: 'Tengeneza Akaunti',
    email: 'Barua pepe',
    emailOrPhone: 'Barua pepe au Nambari ya Simu',
    password: 'Neno la siri',
    welcomeBack: 'Karibu Tena!',
    enterPassword: 'Tafadhali ingiza neno lako la siri',
    enterOneTimePassword: 'Tafadhali weka msimbo wa tarakimu 6',
    codeSentTo: 'Msimbo umetumwa kwa',
    passwordReminder: 'Kumbuka. herufi 8 pamoja na nambari 1 na alama ya tofauti 1',
    forgotYourPassword: 'Je, umesahau neno lako la siri? Weka upya',
    here: 'hapa',
    resetYourPassword: 'Weka Upya Neno Lako la Siri',
    sendResetPasswordLink: 'Tuma Kiungo cha Kuweka Upya Neno la Siri',
    resetLinkInstructions: 'Kiungo cha kuweka upya neno lako la siri kimetumwa kwa ',
    checkSpamFolder: 'Iwapo hautaona kiungo hicho, tazama sehemu ya spam katika barua pepe yako.',
    loginAgain: 'Ingia tena',
    dontHaveAccount: 'Je, huna akaunti? Tafadhali fungua akaunti kupitia kiungo kilichotumwa kwa barua pepe yako.',
    verifyPhoneNumber: 'Thibitisha Nambari Yako ya Simu',
    sendOTP: 'Tuma Nambari ya Uthibitishaji',
    verifyOTP: 'Thibitisha Nambari ya Uthibitishaji',

    // QuickResources Component Variables
    quickResources: 'Nyenzo za Haraka',
    checkBalance: 'Angalia Salio',
    findAnATM: 'Tafuta ATM',
    setPin: 'Weka Pin',
    setPinInfo: 'Hii ni tarakimu 4 ambayo hupaswi kushiriki na mtu yeyote.',

    // Frequently Asked Questions
    faqHeader: 'Maswali Yanayoulizwa Mara kwa Mara',
    faqQuestion1: 'Je, ninahitajika kuiwezesha kadi yangu kabla ya kuitumia?',
    faqAnswer1: 'Hapana, hauhitaji kufanya lolote kabla ya kuanza kuitumia kadi yako. Ilimradi tu kuna salio kwenye kadi yako, basi unaweza kutumia kadi yako.',
    faqQuestion2: 'Maeneo gani ninaweza kuitumia kadi yangu mpya?',
    faqAnswer2: 'Mahali popote wanapopokea malipo ya kadi, ili mradi tu programu yako haina masharti yoyote ya matumizi. Vikwazo vinaweza pia kuwekwa kwenye maduka yanayohitaji kitambulisho. Iwapo shirika lako lina masharti ya matumizi, basi watakwambia kabla ya kukupa kadi yako.',
    faqQuestion3: 'Je, ninaweza kutumia kadi yangu kutoa pesa taslimu kwenye ATM?',
    faqAnswer3: 'Inategemea kama shirika lako linakuruhusu kutowa pesa kwenye ATM. Tafadhali wasiliana na shirika lako ili kufahamu. Iwapo wanaruhusu, unahitaji kuweka PIN kwanza. Weka PIN yako kwa kubonyeza kitufe cha “Weka PIN” kilichopo juu.',
    faqQuestion4: 'Ninawezaje kuweka PIN?',
    faqAnswer4: 'Kwa kubonyeza kitufe cha “Weka Pin”, utaweza kuanza mchakato wa kuweka PIN yako. Uwe na kadi yako mkononi. Fuata maelekezo.',
    faqQuestion5: 'Ninawezaje kuweka upya PIN yangu?',
    faqAnswer5: 'Kwa kubonyeza kitufe cha “Weka Pin”, utaweza kuanza mchakato wa kuweka PIN yako. Uwe na kadi yako mkononi. Fuata maelekezo. Maelekezo hayo yatafanana na uliyokutana nayo ulipokuwa ukiweka pin yako kwa mara ya kwanza.',
    faqQuestion6: 'Nimepoteza kadi yangu, au kuna mtu ameiiba. Nifanye nini?',
    faqAnswer6: 'Wasiliana na huduma ya msaada ya GiveCard (barua pepe iliyopo chini au piga simu namba 908-529-0955). GiveCard itafuata utaratibu wa kufunga kadi hiyo na kukutumia mpya.',
    faqQuestion7: 'Kadi yangu imekataliwa. Kwanini?',
    faqAnswer7: 'Kuna sababu kadhaa za kusababisha kadi yako kukataliwa. Tatizo kuu zaidi ni kutokuwa na salio la kutosha. Matatizo mengine ni pamoja na kubonyeza kitufe cha kubatilisha pale unapoombwa kuingiza pin, pia inaweza kuwa ni kasoro tu ya mashine ya kusomea kadi au shirika lako limeweka sheria ya kusimamia matumizi. Wasiliana na shirika lako ili kufahamu. Wauzaji wengine wanaweza kukataa kadi kwa sababu ya sheria zao za kusimamia miamala na uendeshaji. Unaweza kutatua tatizo hili kwa kuwasiliana na muuzaji moja kwa moja, na anaweza kutoa idhini maalumu iwapo utathibitisha mtoa kadi wako ameidhinisha muamala husika.',
    faqQuestion8: 'Nitumie anwani gani ya malipo ninapofanya manunuzi ya mtandaoni?',
    faqAnswer8: 'Ikiwa una kadi ya kushikika, tafadhali tengeneza “Anwani ya Malipo” katika sehemu ya mmiliki wa kadi katika tovuti au wasiliana na huduma ya msaada ya GiveCard. Kama una kadi ambayo sio ya kushikika(virtual card), anwani ya malipo ni 123 GiveCard Way, New York, NY, 10001.',
    faqQuestion9: 'Niwasiliane na nani kuhusu salio kama halitoshi?',
    faqAnswer9: 'Wasiliana na shirika lililokupa kadi yako. Shirika hilo litaweza kukupa taarifa kuhusu salio la pesa zako.',

    // Additional Support Footer
    furtherQuestionsInfo: 'Kwa maswali na masuala mengine zaidi, tafadhali wasiliana na ',
    supportEmailAndPhoneNumber: 'au piga simu namba 908-529-0955',
    customerSupportHours: 'Huduma kwa wateja inapatikana kuanzia saa 3 asubuhi mpaka saa 12 jioni majira ya E.S.T Jumatatu mpaka Ijumaa.*',
    holidaysSupportException: '*Sikukuu zitaathiri mida hii',

    // Card Details
    confirmCardInformation: 'Thibitisha Taarifa za Kadi',
    last6Digits: ' Nambari 6 za Mwisho Kwenye Namba ya Kadi',
    organizationName: 'Jina la Shirika Lililopo Nyuma ya Kadi',
    expirationDate: 'Tarehe ya Mwisho ya Matumizi',
    invalidError: 'Taarifa za kadi ni batili. Jaribu tena',

    // Set Pin
    last6Error: 'Muundo wa nambari 6 za mwisho zilizowekwa ni batili',
    expirationError: 'Muundo wa tarehe ya mwisho ya matumizi ni batili',
    setNewPin: 'Weka Pin mpya',
    confirmNewPin: 'Thibitisha Pin Mpya',
    pinLengthError: 'Pin inahitaji kuwa na namba 4.',
    pinMatchingError: 'Pin hazifanani.',
    pinSetSuccess: 'Umefanikiwa!',
    yourNewPinIs: 'Pin yako mpya ni:',
    doNotSharePin: 'Usimjulisha mtu yeyote mwingine pin yako.',
    pinInstructions: 'Sasa unaweza kutumia pin hii kutoa pesa au kukamilisha muamala wowote utakaoomba pin.',
    alreadySetPin: 'Inaonekana kama PIN yako tayari imeshawekwa!',
    accountLoginNeededForPinReset: 'Tafadhali ingia katika akaunti yako ya GiveCard ili kuweka upya PIN yako au wasiliana nasi kwa msaada kupitia',
    oops: 'Oops!',
    expirationDateExample: 'MWEZI/MWAKA',
    yourCardIs: 'Kadi yako ni',
    cantSetPinWithThisStatus: 'Huwezi kuweka pin kwenye kadi yenye hali hii.',
    reachOutToYourOrgOrGiveCard: 'Tafadhali wasiliana na shirika lako au kwa',
    ifItsAMistake: 'kama unaamini hili ni kosa.',

    // Lock Card
    unlockCardPromptHeader: 'Je, ungependa kuifunga kadi hii?',
    unlockCardPromptDetails: 'Kadi hii itaruhusu miamala kuendelea kufanyika na pesa kutumika.',
    unlockCardButton: 'Ndiyo, fungua kadi hii.',
    unlockSuccessful: 'Kadi yako imefanikiwa kufunguliwa.',

    lockCardPromptHeader: 'Je, una uhakika unataka kufunga kadi hii?',
    lockCardPromptDetails: 'Kadi hii haitakuwa tena na uwezo wa kufanya miamala wala kutumia pesa wakati ikiwa imefungwa. Unaweza kuifungua wakati wowote.',
    lockCardButton: 'Ndiyo, funga kadi hii.',
    lockSuccessfulHeader: ' Kadi yako imefanikiwa kufungwa.',
    lockSuccessfulDetails: 'Kadi hii haitakuwa na uwezo wa kutumia pesa yoyote au au kukamilisha muamala wowote kwa sasa.',
    unlockHowTo: 'Kama unataka kufungua kadi hii, pita juu ya kadi katika ukurasa wa kadi na itakupeleka moja kwa moja katika mchakato wa kuifungua.',

    // Check Balance
    currentBalance: 'Salio la Sasa',
    yourCardBalance: 'Salio la kadi yako ni:',
    contactSupportEmail: 'Kwa swali au suala lolote, tafadhali wasiliana na huduma ya msaada',

    // Create an Account
    createNewAccountSubheading: 'Kabla ya kutengeneza akaunti, tunahitaji kuthibitisha taarifa zako za GiveCard. Utahitajika kufanya hivi mara moja tu!',
    wantToKnowMore: 'Je, unataka kufahamu taarifa zaidi kuhusu kadi yako?',
    createAPassword: 'Weka neno la siri',
    passwordRequirements: 'Mahitaji ya neno la siri: herufi 8 pamoja na nambari 1 na alama ya tofauti 1',
    confirm: 'Thibitisha',
    confirmPassword: 'Thibitisha Neno la Siri',
    alreadyHaveAnAccount: 'Je, tayari una akaunti? Ingia ',
    accountAlreadyExistsError: 'Whoops! Tayari kuna akaunti ambayo inatumia barua pepe hii.',
    cardAlreadyLinkedError: 'Kadi hii tayari imeunganishwa na akaunti nyingine',
    accountCreationError: 'Kasoro imetokea wakati wa kutengeneza akaunti.',
    error: 'Kasoro',
    tryAgainMessage: 'Tafadhali jaribu tena, au wasiliana na huduma ya msaada',
    passwordMismatchError: 'Neno la siri halifanani.',
    passwordInvalidError: 'Neno la siri lazima liwe na herufi 8, angalau namba moja na alama ya tofauti moja.',
    welcome: 'Karibu!',
    invalidTokenPage: 'Samahani, kiungo sio sahihi! Tafadhali bonyeza kiungo tulichokutumia kwenye barua pepe ili kutengeneza akaunti.',
    createAccountToAccessVirtualCard: 'Tengeneza akaunti ili kutumia Kadi yako Isiyo ya Kushikika (Virtual Card)!',
    alreadyHaveAccount: 'Je, tayari una akaunti?',
    firstName: 'Jina la Kwanza',
    lastName: 'Jina la Ukoo',
    firstAndLastName: 'Jina la Kwanza na la Ukoo',
    phoneNumber: 'Namba ya Simu',
    createAcctToViewPortal: 'Tengeneza akaunti ili kutazama mtandao wa wa shirika lako.',
    yourEmailAddress: 'Anwani yako ya Barua pepe',
    haventMetPasswordRequirements: 'Bado haujakidhi mahitaji yote ya neno la siri.',
    createProfile: 'Tengeneza Wasifu',
    creatingUser: 'Kutengeneza Mtumiaji',
    invalidEmail: 'Barua pepe ni batili',
    invalidPhoneNumber: 'Namba ya Simu ni batili',
    phoneRequirements: 'Inahitaji kuwa na tarakimu 10, na isiwe na kistari chochote',
    createAccountSubheading: 'Tunakusanya baadhi ya taarifa za msingi!',
    enterMobilePhoneNumber: 'Tafadhali ingiza namba yako ya simu ya mkononi',
    requirements: 'Mahitaji:',
    atLeast8Chars: '• Angalau herufi 8',
    oneNumber: '• Nambari 1',
    oneSpecialCharacter: '• Alama ya tofauti 1 kama',
    passwordsMatch: '• Neno la siri halifanani',
    alreadyHaveAdminAccount: 'Je, tayari una akaunti ya usimamizi ya GiveCard?',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'Tazama kipengele cha taarifa! Sasa unaweza kupokea taarifa za miamala yako ya hivi karbuni kupitia barua pepe! Bonyeza kitufe cha taarifa ili kufahamu zaidi.',
    cardActivityHistory: 'Historia ya Shughuli za Kadi',
    transactions: 'Miamala',
    loadHistory: 'Fungua Historia',
    date: 'Tarehe',
    transaction: 'Muamala',
    merchantCategory: 'Kategoria ya Mfanyabiashara',
    amount: 'Kiasi',
    timeLoaded: 'Muda wa Kuweka Pesa',
    amountLoaded: 'Kiasi cha Pesa Kilichowekwa',
    lockCard: 'Funga Kadi',
    unlockCard: 'Fungua Kadi',
    home: 'Nyumbani',
    emailNotificationsHeader: 'Taarifa ya Barua pepe',
    emailNotificationsSubheader1: 'Pokea taarifa zilizotumwa kwenye barua pepe yako kuhusu shughuli za kadi yoyote.',
    emailNotificationsSubheader2: 'Unaweza kuzuia kuzipokea muda wowote.',
    notifications: 'Taarifa',
    recentTransactions: 'Miamala ya Hivi Karibuni',
    recentTransactionsSubheader: 'Pokea taarifa za miamala yako ya hivi karibuni ili kufahamu matumizi yako na kuepusha utapeli.',
    cardDeclines: 'Kadi Ilipokataliwa',
    cardDeclinesSubheader: 'Pokea taarifa pale kadi yako inapokataliwa ili kufahamu sababu.',
    cardLoads: 'Pesa Zilizowekwa kwenye Kadi',
    cardLoadsSubheader: 'Pokea taarifa pale pesa inapoongezwa kwenye kadi yako.',
    sendingTo: 'Inatumwa kwa: ',
    saving: 'Inahifadhiwa...',
    save: 'Hifadhi',
    cardCanOnlyBeUsedAt: 'Mpango wako unaruhusu kadi hii kutumika kwa wauzaji wafuatao pekee:',
    moreInfoAboutMerchantRestrictions: 'Kwa maelezo zaidi kuhusu vikwazo vya wauzaji, tafadhali wasiliana na programu yako moja kwa moja. GiveCard haiwajibikii vikwazo hivi.',

    // Transaction Table
    pending: 'Yanasubiri',
    pendingPurchases: 'Manunuzi Yanayosubiri',
    pendingPurchasesDescription: 'Manunuzi yanayosubiri ni malipo ambayo yamethibitishwa na mfanyabiashara, lakini bado hayajawasilishwa, na kawaida huwa yanawasilishwa ndani ya siku 5. Yanawekwa pia kwenye salio lako la sasa.',
    cleared: 'Yamepitishwa',
    clearedPurchases: 'Manunuzi Yaliyopitishwa',
    clearedPurchasesDescription: 'Manunuzi Yaliyopitishwa yamethibitishwa na mfanyabiashara na kuruhusiwa.',
    loadingTxs: 'Miamala Inapakiwa',

    // Full Card Details
    errorGettingCardDetails: 'Kumetokea kasoro wakati wa kuchukua taarifa za kadi.',
    pleaseRefreshThePage: 'Tafadhali fungua tena upya ukurasa.',
    cardNumber: 'Namba ya Kadi',
    howDoIUseMyVirtualCard: 'Ninatumiaje kadi ambayo sio ya kushikika?',
    usingAVirtualCard: 'Kutumia Kadi ambayo sio ya kushikika',
    clickThe: 'Bonyeza',
    toSeeFullCardDetails: 'ili kuona maelezo kamili ya kadi',
    cardCanBeUsedAnywhere: 'Kadi hii inaweza kutumika mahali popote wanapokubali kadi za malipo ya kabla za Mastercard',
    cardInstructions: 'Tumia kadi hii mtandaoni kwa kunakili na kuweka taarifa zinazoonyeshwa katika tovuti hii',
    digitalWalletInstructions: 'Ongeza kadi yako kwenye',
    digitalWalletInstructions2: 'kwa kuingiza taarifa za kadi kwenye simu yako. Maelekezo zaidi yaliyounganishwa.',
    orLowercase: 'au',
    billingAddress: 'Kama manunuzi yanahitaji anwani ya malipo, tafadhali tumia ifuatayo:',
    needSupport: 'Je, unahitaji msaada?',
    teamReadyToHelp: 'Timu yetu ya watoa msaada ipo tayari kukusaidia!',
    pleaseEmail: 'Tafadhali tuma barua pepe ',
    call: 'piga simu',

    // Billing Address
    billingAddressTitle: 'Anwani ya Malipo',
    yourBillingAddress: 'Anwani yako ya Malipo kwa Manunuzi ya Mtandaoni',
    pleaseUseThis: 'Tafadhali tumia answani hii ya malipo kila unapofanya manunuzi ya mtandaoni.',

    // Error Modal
    whoops: 'Whoops',
    encounteredAnError: 'Tumekutana na kasoro ',
    tryAgain: 'Jaribu tena',
    ifProblemPersists: 'Kama tatizo hili likiendelea, tafadhali wasiliana na mtu wako wa mawasiliano wa GiveCard',
    forDevelopers: 'Kwa watengenezaji',
  },

  da: {
    // Platform Home Page
    for: 'برای',
    cardholders: 'دارندگان کارت',
    admins: 'مدیران',
    cardholdersLandingSubheading: 'آیا شما یک گیف کارت را دریافت کرده‌اید و به دنبال اطلاعات بیشتر هستید؟',
    adminLandingSubheading: 'به داشبورد گیف کارت خود وارد شوید!',

    cardholderWelcome: 'امید اینجا آغاز می‌شود',
    cardholderSubWelcome: 'ما خوشحال استیم که شما گیف کارت خود را دریافت کرده‌اید.',
    memberSubWelcome: 'ما خوشحال استیم که شما به ما یکجای می‌شوید.',

    // CardholderLogin Component
    login: 'وارد شوید',
    enterAValidEmail: 'لطفاً یک ایمیل معتبر را وارد کنید.',
    or: 'یا',
    exploreCard: 'میخواهید بیشتر درباره کارت خود بدانید؟',
    createAnAccount: 'یک حساب کاربری را ایجاد کنید',
    email: 'ایمیل',
    emailOrPhone: 'ایمیل یا تلفن',
    password: 'پاسورد',
    welcomeBack: 'دوباره خوش آمدید!',
    enterPassword: 'لطفاً پاسورد خود را وارد کنید',
    enterOneTimePassword: 'لطفا کد 6 رقمی را وارد کنید',
    codeSentTo: 'کد ارسال شده به',
    passwordReminder: 'به یاد داشته باشید که پاسورد شما دارای ۸ حروف شامل ۱ عدد و ۱ حرف خاص باشد',
    forgotYourPassword: 'آیا پاسورد خود را فراموش کرده‌اید؟ دوباره آن را.',
    here: 'در اینجا تنظیم کنید',
    resetYourPassword: 'پاسورد خود را دوباره تنظیم کنید',
    sendResetPasswordLink: 'ارسال لینک دوباره تنظیم کردن پاسورد',
    resetLinkInstructions: ' یک لینک برای دوباره تنظیم کردن پاسورد به شما ارسال شده است',
    checkSpamFolder: 'اگر لینک را نمی‌بینید، لطفاً فولدر سپم خود را ببنید.',
    loginAgain: 'دوباره وارد شوید',
    dontHaveAccount: 'حساب نداريد؟ لطفاً از طریق لینکی که به ایمیل شما ارسال شده است، حساب کاربری ایجاد کنید.',
    verifyPhoneNumber: 'شماره تلفن خود را تأیید کنید',
    sendOTP: 'ارسال کد تایید',
    verifyOTP: 'کد تأیید را تأیید کنید',

    // QuickResources Component Variables
    quickResources: 'منابع سریع',
    checkBalance: 'بیلانس را ببندید',
    findAnATM: 'یک ای تی ام را پیدا کنید',
    setPin: 'یک پین را تنظیم کنید',

    // Frequently Asked Questions
    faqHeader: 'پرسش‌های متداول',
    faqQuestion1: 'آیا باید کارت خود را قبل از استفاده فعال کنم؟',
    faqAnswer1: ' نه، شما نیازی به انجام هیچ کاری قبل از شروع استفاده از کارت خود ندارید. تا زمانی که موجودی در کارت شما وجود داشته باشد، می‌توانید از کارت خود استفاده کنید.',
    faqQuestion2: 'کارت جدیدم را کجا می‌توانم استفاده کنم؟',
    faqAnswer2: ' هر جایی که کارتهای دبیت پذیرفته شوند، می‌توانید از کارت جدید خود استفاده کنید، به شرطی که برنامه شما هیچ قوانین خرج کردنی نداشته باشد. در برخی از فروشگاه‌ها که احراز هویت الزامی است، ممکن است محدودیت‌هایی وجود داشته باشد. اگر سازمان شما قوانین خرج کردنی دارد، قبل از توزیع کارت به شما، به شما اطلاع خواهند داد.',
    faqQuestion3: 'آیا می‌توانم از کارت خود در یک دستگاه خودپرداز برای برداشت وجه نقد استفاده کنم؟',
    faqAnswer3: ' این بستگی به این دارد که آیا سازمان شما اجازه برداشت از خودپرداز را می‌دهد یا خیر. لطفاً با سازمان خود تماس بگیرید تا بفهمید. اگر اجازه داده شده باشد، نیاز دارید ابتدا یک پین تعیین کنید. با کلیک بر روی دکمه "تعیین پین" بالا، می‌توانید فرایند تنظیم پین خود را آغاز کنید.',
    faqQuestion4: 'چگونه می‌توانم پین خود را تنظیم کنم؟',
    faqAnswer4: ' با کلیک بر روی دکمه "تعیین پین"، می‌توانید فرایند تنظیم پین خود را آغاز کنید. کارت خود را در دست داشته باشید و دستورالعمل‌ها را دنبال کنید.',

    faqQuestion5: ' چگونه می‌توانم پین خود را دوباره تنظیم کنم؟',
    faqAnswer5: ' با کلیک بر روی دکمه "تنظیم پین"، می‌توانید فرآیند تنظیم مجدد پین خود را آغاز کنید. کارت خود را در دسترس داشته باشید. دستورالعمل‌ها را دنبال کنید. آنها مشابه بار اولیه‌ای هستند که پین را تنظیم کردید.',

    faqQuestion6: ' کارت خود را گم کردم یا کسی آن را دزدید. چه باید کنم؟',
    faqAnswer6: ' با خدمات مشتریان گیف کارت تماس بگیرید (ایمیل زیر و یا با شماره ۰۹۵۵-۵۲۹-۹۰۸ تماس بگیرید). گیف کارت فرآیند قفل کردن کارت و ارسال یک کارت جدید برای شما را انجام خواهد داد.',
    faqQuestion7: ' کارت من رد شد. چرا؟',
    faqAnswer7: ' چندین دلیل ممکن است باعث رد شدن یک کارت شود. مشکل رایج ‌تر در صورت نبود بیلانس کافی است. مشکلات دیگر شامل کلیک کردن لغو هنگامی که درخواست پین را می‌کند، خطا در دستگاه خواننده کارت یا تنظیم قوانین مصرف توسط سازمان‌ها است. با سازمان خود تماس بگیرید تا اطلاعات لازم را بدست آورید. فروشگاه‌های دیگر ممکن است براساس قوانین خود در مورد ترانزکشن‌ها رد کنند. می‌توانید این مشکل را با تماس مستقیم با فروشگاه حل کنید و اگر ترانزکشن شما توسط صادرکننده کارت تأیید شده است، آنها ممکن است قادر به ارائه یک تأیید اضافی باشند.',

    faqQuestion8: ' کدام آدرس صورتحساب باید برای خریدهای آنلاین استفاده کنم؟',
    faqAnswer8: ' اگر شما یک کارت فیزیکی دارید، لطفاً یک حساب کاربری بسازید و در پنل دارنده کارت روی "آدرس صورتحساب" کلیک کنید یا با خدمات مشتریان گیف کارت تماس بگیرید. اگر شما یک کارت مجازی دارید، آدرس صورتحساب ۱۲۳ سرک گیف کارت، شهر نیویارک، ایالت نیویارک، ۱۰۰۰۱ است.',
    faqQuestion9: ' با چه کسی باید درباره کمبود بیلانس تماس بگیرم؟',
    faqAnswer9: ' با سازمانی که به شما کارت داده است تماس بگیرید. این سازمان قادر خواهد بود به شما اطلاعاتی در مورد بیلانس حسابتان را ارائه کند.',

    // Additional Support Footer
    furtherQuestionsInfo: ' برای سوالات و مشکلات بیشتر، لطفاً با ما تماس بگیرید ',
    supportEmailAndPhoneNumber: ' یا با شماره ۰۹۵۵-۵۲۹-۹۰۸ تماس بگیرید.',
    customerSupportHours: ' خدمات مشتریان از ساعت ۹ صبح تا ۶ بعدازظهر به وقت اي.ایس.تي از دوشنبه تا جمعه در دسترس است*.',
    holidaysSupportException: '* تعطیلی‌ها بر این ساعات تأثیر خواهند گذاشت',

    // Card Details
    confirmCardInformation: 'تأیید اطلاعات کارت',
    last6Digits: 'شش رقم آخر شماره کارت',
    organizationName: 'نام سازمان در پشت کارت',
    expirationDate: 'تاریخ انقضای کارت',
    invalidError: 'معلومات کارت نامعتبر است. لطفاً دوباره امتحان کنید',

    // Set Pin
    last6Error: 'قالب ورودی شش رقم آخر نامعتبر است',
    expirationError: 'قالب ورودی تاریخ انقضا نامعتبر است',
    setNewPin: 'یک پین نو را تنظیم کنید',
    confirmNewPin: 'پین نو را تائید کنید',
    pinLengthError: 'پین باید حد اقل ۴ رقم باشد.',
    pinMatchingError: 'پین‌ها مطابقت ندارند.',
    pinSetSuccess: 'موفقیت آمیز!',
    yourNewPinIs: 'پین نو شما:',
    doNotSharePin: 'پین خود را با هیچ کسی شریک نسازید',
    pinInstructions: 'اکنون می‌توانید این پین را برای هر معامله (ترانزکشن) یا برداشتی که از شما پین درخواست می‌کند، استفاده کنید.',
    alreadySetPin: 'معلوم می شود که پین شما قبلا تنظیم شده!',
    accountLoginNeededForPinReset: ' لطفاً وارد حساب کاربری گیف کارت خود شوید تا پین خود را باز تنظیم کنید یا خدمات مشتریان تماس بگیرید',
    oops: ' اوه!',
    expirationDateExample: 'MM/YY',
    yourCardIs: 'کارت شماست',
    cantSetPinWithThisStatus: 'نمی توانید روی کارتی با این وضعیت پین تنظیم کنید.',
    reachOutToYourOrgOrGiveCard: 'لطفا با سازمان خود تماس بگیرید یا به',
    ifItsAMistake: 'اگر معتقدید این یک اشتباه است',

    // Lock Card
    unlockCardPromptHeader: 'آیا شما می خواهید که این کارت را باز کنید؟',
    unlockCardPromptDetails: ' کارت به طور فوری اجازه می‌دهد تا ترانزکشن ها ادامه پیدا کند و بیلانس مصرف شود.',
    unlockCardButton: 'بلی، این کارت را باز کنید.',
    unlockSuccessful: 'کارت شما با موفقیت باز شد.',

    lockCardPromptHeader: 'آیا مطمئن هستید که می‌خواهید این کارت را قفل کنید؟',
    lockCardPromptDetails: 'وقتیکی که کارت قفل باشد، کارت دیگر قادر به انجام ترانزکشن‌ها و دسترسی به بیلانس نمی‌باشد',
    lockCardButton: 'بلی،‌ این کارت را قفل کنید.',
    lockSuccessfulHeader: 'کارت شما با موفقیت قفل شد.',
    lockSuccessfulDetails: ' از این لحظه به بعد، این کارت قادر به استفاده از هیچ گونه بیلانس یا انجام هیچ ترانزکشنی نخواهد بود.',
    unlockHowTo: ' اگر میخواهید این کارت را باز کنید، نشانگر را بر روی وضعیت در صفحه کارت فردی بگذارید و خودکار به شما در فرایند باز کردن کارت هدایت می‌شود.',

    // Check Balance
    currentBalance: 'بیلانس فعلی',
    yourCardBalance: 'بیلانس کارت شما:',
    contactSupportEmail: 'برای هر سؤال یا مشکل، لطفاً با خدمات مشتریان تماس بگیرید.',

    // Create an Account
    createNewAccountSubheading: ' قبل از ایجاد حساب کاربری، ما باید جزئیات گیف کارت شما را تأیید کنیم. شما تنها باید این کار را یکبار انجام دهید!',
    wantToKnowMore: 'آیا میخواهید اطلاعات بیشتری درباره کارت خود بدانید؟',
    createAPassword: 'یک پاسورد را ایجاد کنید.',
    passwordRequirements: 'مقررات پاسورد: حداقل ۸ حروف، شامل ۱ عدد و ۱ حرف خاص.',
    confirm: 'تائید کنید',
    confirmPassword: 'پاسورد را تائید کنید',
    alreadyHaveAnAccount: ' آیا حساب کاربری دارید؟ وارد شوید. ',
    accountAlreadyExistsError: 'اوه! حسابی با این آدرس ایمیل قبلاً ایجاد شده است.',
    cardAlreadyLinkedError: 'این کارت قبلاً با یک حساب دیگر مرتبط شده است.',
    accountCreationError: 'متأسفانه در ایجاد حساب کاربری خطایی رخ داده است.',
    error: 'اشتباه',
    tryAgainMessage: 'لطفاً دوباره امتحان کنید یا با خدمات مشتریان تماس بگیرید',
    passwordMismatchError: 'پاسورد ها باید مطابقت داشته باشند.',
    passwordInvalidError: 'پاسورد باید شامل ۸ حروف باشد که حداقل ۱ عدد و یک حرف خاص داشته باشد.',
    welcome: 'خوش آمدید!',
    invalidTokenPage: ' ببخشید، لینک اشتباه است! لطفاً بر روی لینک که به شما در ایمیل ارسال کرده‌ایم کلیک کنید تا یک حساب کاربری بسازید.',
    createAccountToAccessVirtualCard: 'برای دسترسی به کارت مجازی خود، یک حساب کاربری بسازید.',
    alreadyHaveAccount: 'آیا حساب کاربری دارید؟',
    firstName: 'اسم اول',
    lastName: 'نام خانوادگی',
    firstAndLastName: 'لطفاً اسم اول و نام خانوادگی خود را وارد کنید',
    phoneNumber: 'نمبر تلیفون',
    createAcctToViewPortal: 'برای مشاهده پورتال سازمان خود، یک حساب کاربری بسازید.',
    yourEmailAddress: 'ایمیل ادرس شما',
    haventMetPasswordRequirements: 'شما تمامی مقررات پاسورد را برآورده نکرده‌اید.',
    createProfile: 'پروفایل را ایجاد کنید',
    creatingUser: 'یوزر را ایجاد کنید',
    invalidEmail: 'ایمیل نامعتبر',
    invalidPhoneNumber: 'شماره تلفن نامعتبر است.',
    phoneRequirements: 'باید ۱۰ رقم داشته باشد، بدون خط تیره (دش)',
    createAccountSubheading: 'بیایید اطلاعات اساسی را جمع‌آوری کنیم!',
    enterMobilePhoneNumber: 'لطفاً شماره تلفن همراه خود را وارد کنید.',
    requirements: 'مقررات:',
    atLeast8Chars: 'حداقل ۸ حروف باشد •',
    oneNumber: '۱ عدد باشد •',
    oneSpecialCharacter: ' یک خرف خاص•',
    passwordsMatch: 'پاسورد ها مطابقت دارند •',
    alreadyHaveAdminAccount: 'حساب مدیریت گیف کارت را زا قبل دارید؟',

    // Cardholder Portal
    notificationFeatureAnnouncement: ' امکان دریافت اعلانات از طریق ایمیل درباره ترانزکشن‌های اخیر شما فعال شده است. برای کسب اطلاعات بیشتر، روی دکمه اعلانات کلیک کنید.',
    cardActivityHistory: 'تاریخچه فعالیت کارت',
    transactions: 'ترانزکشن ها',
    loadHistory: 'تاریخچه در حال باز شدن است',
    date: 'تاریخ',
    transaction: 'ترانزکشن',
    merchantCategory: 'دسته ‌بندی فروشنده',
    amount: 'مقدار',
    timeLoaded: 'وقت تیر میشه',
    amountLoaded: 'مقدار در حال باز شدن است',
    lockCard: 'کارت را قفل کنید',
    unlockCard: 'کارت را باز کنید',
    home: 'صفحه اول',
    emailNotificationsHeader: 'اعلانات ایمیلی',
    emailNotificationsSubheader1: ' درخواست ارسال اعلانات به ایمیل خود را درباره هر فعالیتی که در کارت رخ می‌دهد، بدهید.',
    emailNotificationsSubheader2: 'شما می‌توانید در هر زمان این اعلانات را غیرفعال کنید.',
    notifications: 'اطلاعیه',
    recentTransactions: 'ترانزکشن‌های اخیر',
    recentTransactionsSubheader: ' برای رصد هزینه‌ها و جلوگیری از تقلب، اطلاعیه های را درباره ترانزکشن‌های اخیر خود دریافت کنید.',
    cardDeclines: 'کارت رد شد',
    cardDeclinesSubheader: 'اطلاعیه را دریافت کنید هنگامی که کارت شما رد می‌شود تا دلیل آن را بفهمید.',
    cardLoads: 'Card Loads',
    cardLoadsSubheader: 'اطلاعیه را دریافت کنید هنگامی که پیسه به کارت شما اضافه می‌شود.',
    sendingTo: 'ارسال به: ',
    saving: 'در حال سیف شدن...',
    save: 'سیف',
    cardCanOnlyBeUsedAt: 'برنامه شما فقط اجازه می‌دهد که این کارت در فروشندگان زیر استفاده شود:',
    moreInfoAboutMerchantRestrictions: 'برای اطلاعات بیشتر در مورد محدودیت‌های بازرگان، لطفاً مستقیماً با برنامه خود تماس بگیرید. GiveCard مسئول این محدودیت‌ها نیست.',


    // Transaction Table
    pending: 'در انتظار',
    pendingPurchases: 'خریدهای که در انتظار',
    pendingPurchasesDescription: ' خریدهای در انتظار، هزینه‌هایی هستند که توسط فروشنده تأیید شده اما هنوز منتشر نشده‌اند و معمولاً در عرض ۵ روز منتشر می‌شوند. آنها در بیلانس فعلی شما شامل است.',
    cleared: 'پاک شد',
    clearedPurchases: 'خریدهای پاک شده',
    clearedPurchasesDescription: 'خریدهای پاک شده توسط فروشنده تأیید شده و تصدیق شده‌اند.',
    loadingTxs: 'ترانزکشن ها در حال بازشدن است.',

    // Full Card Details
    errorGettingCardDetails: 'بازیابی جزئیات کارت با خطا مواجه شد.',
    pleaseRefreshThePage: 'لطفاً صفحه را تازه کنید.',
    cardNumber: 'شماره کارت',
    howDoIUseMyVirtualCard: 'چطور می توانم کارت مجازي خود را استفاده کنم؟',
    usingAVirtualCard: 'استفاده از کارت مجازي',
    clickThe: 'کلیک کنید',
    toSeeFullCardDetails: 'برای مشاهده جزئیات کامل کارت',
    cardCanBeUsedAnywhere: 'این کارت می‌تواند در هر جایی استفاده شود که کارت پیش‌پرداخت ماسترکارت را قبول کند.',
    cardInstructions: 'با کاپی و پیست اطلاعات نشان داده شده در این پورتال، از این کارت به صورت آنلاین استفاده کنید',
    digitalWalletInstructions: 'کارت خود را',
    digitalWalletInstructions2: ' با وارد کردن جزئیات کارت در موبایل خود، کارت را اضافه کنید. دستورالعمل‌های بیشتر در لینک مرتبط قابل دسترسی است.',
    orLowercase: 'یا',
    billingAddress: 'اگر برای خرید نیاز به آدرس صورتحساب است، لطفا از آدرس زیر استفاده کنید:',
    needSupport: 'به پیشتبانی ضرورت دارید؟',
    teamReadyToHelp: 'تیم پشتیبانی ما آماده است که کمک کند',
    pleaseEmail: ' لطفاً به ما ایمیل بفرستید',
    call: 'زنگ بزنید',

    // Billing Address
    billingAddressTitle: 'آدرس صورتحساب',
    yourBillingAddress: 'آدرس صورتحساب شما برای خریدهای آنلاین',
    pleaseUseThis: 'لطفاً هر بار که در حال انجام ترانزکشن آنلاین هستید، از این آدرس صورتحساب استفاده کنید.',

    // Error Modal
    whoops: 'اوه',
    encounteredAnError: 'ببخشید، با خطا مواجه شدیم.',
    tryAgain: 'دوباره امتحان کنید',
    ifProblemPersists: 'اگر این مشکل ادامه پیدا کند، لطفاً با مخاطب گیف کارت خود تماس بگیرید.',
    forDevelopers: 'برای توسعه ‌دهندگان (دیویلپر ها)',

  },

  pa: {
    // Platform Home Page
    for: 'لپاره',
    cardholders: 'د کارډ لرونکي',
    admins: 'مدیران',
    cardholdersLandingSubheading: 'ایا تاسو ګیف کارډ ترلاسه کړی او د نورو معلوماتو په لټه کې یاست؟',
    adminLandingSubheading: 'خپل د ګیف کارډ ډشبورډ ته ننوځئ!',

    cardholderWelcome: 'هیله دلته شروع کیږې',
    cardholderSubWelcome: 'موږ خوښ یو چې تاسو خپل ګیف کارډ ترلاسه کړی.',
    memberSubWelcome: 'موږ خوښ یو چې تاسو له موږ سره یوځای کیږئ.',

    // CardholderLogin Component
    login: 'ننوځئ',
    enterAValidEmail: ' مهرباني وکړئ یو معتبر بریښنالیک ولیکئ.',
    or: 'یا',
    exploreCard: 'غواړئ چې د خپل کارډ په اړه نور هم پوه شئ؟',
    createAnAccount: 'یو اکونټ پرانیزئ',
    email: 'بریښنالیک',
    emailOrPhone: 'بریښنالیک یا تلیفون',
    password: 'پاسورډ',
    welcomeBack: 'بیا ښه راغلاست!',
    enterPassword: 'مهرباني وکړئ خپل پاسورډ دننه کړئ',
    enterOneTimePassword: 'مهرباني وکړئ د 6 عددي کوډ دننه کړئ',
    codeSentTo: 'کوډ ته لیږل شوی',
    passwordReminder: 'په یاد ولرئ چې ستاسو پاسورډ باید ۸ حروف ولري چې ۱ شمیره او ۱ ځانګړي حرف پکې شامل وي',
    forgotYourPassword: 'ایا تاسو خپل پاسورډ هیر کړی؟ بیا یې له سره',
    here: 'دلته تنظیم کړئ',
    resetYourPassword: 'خپل پاسورډ له سره تنظیم کړئ',
    sendResetPasswordLink: 'ستاسو د پاسورډ بیا تنظیمولو لپاره دې ته یو لینک استول شوئ',
    resetLinkInstructions: ' ستاسو د پاسورډ د بیا تنظیم کولو لینک تاسو ته لیږل شوی',
    checkSpamFolder: 'که تاسو لینک نه ګورئ، مهرباني وکړئ خپل د سپم فولډر وګورئ.',
    loginAgain: 'یو ځل بیا دننه شئ',
    dontHaveAccount: 'حساب نه لرئ؟ مهرباني وکړئ د هغه لینک له لارې یو حساب جوړ کړئ چې ستاسو بریښنالیک ته لیږل شوی و.',
    verifyPhoneNumber: 'د خپل تلیفون شمیره تایید کړئ',
    sendOTP: 'د تایید کوډ واستوئ',
    verifyOTP: 'د تایید کوډ تایید کړئ',

    // QuickResources Component Variables
    quickResources: 'چټکې سرچینې',
    checkBalance: 'خپل بیلانس وګورئ',
    findAnATM: 'يو ای ټي ایم پیدا کړئ',
    setPin: 'یو پین تنظیم کړئ',
    setPinInfo: 'په خپل کارډ کې د پین تنظیمولو پروسه پیل کړئ',

    // Frequently Asked Questions
    faqHeader: 'عامې پوښتنې',
    faqQuestion1: ' ایا زه باید خپل کارډ د کارولو دمخه فعال کړم؟',
    faqAnswer1: ' نه، تاسو اړتیا نلرئ چې د کارډ کارولو د مخه څه وکړئ. کله چې ستاسو په کارډ کې بیلانس شتون ولري، تاسو کولی شئ خپل کارډ وکاروئ.',
    faqQuestion2: 'زه د خپل نوي کارډ څخه چیرته استفاده کولی شم؟',
    faqAnswer2: ' تاسو کولی شئ خپل نوی کارډ هر هغه ځای کې وکاروئ چې ډیبیټ کارډونه مني، تر څو چې ستاسو سازمان د لګښت مقررات ونلري. ممکن په ځینو پلورنځیو کې چې تصدیق ته اړتیا وي محدودیتونه شتون ولري. که ستاسو سازمان د لګښتونو مقررات ولري، دوی به تاسو ته د کارډ درکولو دمخه خبر درکړي.',
    faqQuestion3: 'ایا زه کولی شم خپل کارډ په ای ټي ايم کې د پیسو ایستلو لپاره وکاروم؟',
    faqAnswer3: ' دا پدې پورې اړه لري چې آیا ستاسو سازمان ای ټي ايم ته اجازه ورکوي که نه. د دې خبرې موندلو لپاره مهرباني وکړئ د خپل سازمان سره اړیکه ونیسئ. که اجازه ورکړل شوې وي، تاسو اړتیا لرئ لومړی پین تنظیم کړئ. په پورته د (پین تنظیم) تڼۍ باندې کلیک کولو سره، تاسو کولی شئ د خپل پین ترتیبولو پروسه پیل کړئ.',
    faqQuestion4: 'څه ډول کولی شم خپل پین تنظیم کړم؟',
    faqAnswer4: ' د "پاسورډ تنظیم" تڼۍ باندې په کلیک کولو سره، تاسو کولی شئ د خپل پاسورډ ترتیبولو پروسه پیل کړئ. خپل کارډ ځان سره ولرئ او لارښوونې تعقیب کړئ.',

    faqQuestion5: ' زه څنګه خپل پین له سره تنظیم کړم؟',
    faqAnswer5: ' د (پین تنظیم) تڼۍ په کلیک کولو سره، تاسو کولی شئ د خپل پین بیا تنظیمولو پروسه پیل کړئ. خپل کارډ په لاس کې ولرئ. لارښوونې تعقیب کړئ. دوی به د لومړي ځل په څیر څرګند شي کله چې تاسو پین تنظیم کړئ.',
    faqQuestion6: ' ما څخه خپل کارډ ورک شو، یا یو چا غلا کړ. زه باید څه وکړم؟',
    faqAnswer6: ' د ګیف کارډ د پیریدونکو خدمت سره اړیکه ونیسئ (لاندې پتې ته بریښنالیک واستوئ یا په دې شمیرې ۹۰۸-۵۲۹-۰۹۵۵ زنګ ووهئ). ګیف کارډ به د زوړ کارډ د لاک کولو او تاسو ته د نوي کارډ لیږلو پروسه پیل کړئ.',
    faqQuestion7: ' زما کارډ رد شو. ولې؟',
    faqAnswer7: ' ډیری دلیلونه شتون لري چې ستاسو کارډ ولې رد شو. ترټولو عامه ستونزه دا ده چې ممکن تاسو کافي بیلانس ونلرئ. بیله ستونزه دا کیدی شي چې د پین غوښتلو په صورت کې تاسو د رد تڼۍ کیکاږئ، د کارډ لوستونکي تېروتنه یا ستاسو سازمانونو د لګښت مقررات ټاکلي دي. د موندلو لپاره خپل سازمان سره اړیکه ونیسئ. نور پلورنځي د دې توان لري چې د خپلو معاملو او کمولو قواعدو پراساس ستاسو کارډ رد کړي. تاسو ممکن وکولی شئ دا ستونزه په مستقیم ډول د پلورنځي سره په تماس کې حل کړئ، او دوی ممکن وکولی شي د بیرته راګرځولو وړاندیز وکړي که تاسو تایید کړئ چې ستاسو د کارډ جاري کونکي لیږد تایید کړی.',

    faqQuestion8: ' زه باید د آنلاین پیرود لپاره کوم د بیل ادرس وکاروم؟',
    faqAnswer8: 'که تاسو فزیکي کارډ لرئ، مهرباني وکړئ یو اکونټ جوړ کړئ او د کارډ لرونکي پینل کې د "بیل ادرس" باندې کلیک وکړئ یا د ګیف کارډ پیرودونکي خدمت سره اړیکه ونیسئ. که تاسو مجازی کارډ لرئ، د ګیف کارډ ۱۲۳ سړک، نیویارک ښار، نیویارک ایالت، ۱۰۰۰۱ د بیل ادرس په توګه استعمالولی شئ.',
    faqQuestion9: ' زه باید د بیلانس نشتوالي په اړه له چا سره اړیکه ونیسم؟',
    faqAnswer9: ' د هغه سازمان سره اړیکه ونیسئ چې تاسو ته یې کارډ جوړ کړی. دا اداره به تاسو ته ستاسو د اکونټ بیلانس په اړه معلومات درکړي.',

    // Additional Support Footer
    furtherQuestionsInfo: ' د نورو پوښتنو او ستونزو لپاره، مهرباني وکړئ موږ سره اړیکه ونیسئ',
    supportEmailAndPhoneNumber: ' ۹۰۸-۵۲۹-۰۹۵۵ ته زنګ ووهئ.',
    customerSupportHours: ' د پیرودونکي خدمت د دوشنبې څخه تر جمعې پورې د سهار له ۹ بجو څخه تر ۶ بجو پورې شتون لري. *',
    holidaysSupportException: 'تړل به په دې ساعتونو اغیزه وکړي *',

    // Card Details
    confirmCardInformation: 'د کارډ معلومات تایید کړئ',
    last6Digits: 'د کارډ شمیرې وروستۍ شپږ عددونه',
    organizationName: 'د کارډ په شا کې د سازمان نوم ',
    expirationDate: 'د کارډ د ختمیدو نیټه ',
    invalidError: ' د کارډ معلومات ناسم دي. مهرباني وکړئ بیا هڅه وکړئ',

    // Set Pin
    last6Error: ' د ان پټ فارمیټ وروستي شپږ عددونه ناسم دي',
    expirationError: ' د ختمیدو نیټې بڼه ناسمه ده',
    setNewPin: 'یو نوی پین تنظیم کړئ ',
    confirmNewPin: 'نوی پین تنظیم کړئ',
    pinLengthError: ' پین باید لږترلږه ۴ عدده وي.',
    pinMatchingError: ' پینونه سره سمون نه خوري.',
    pinSetSuccess: ' بریالۍ شوه',
    yourNewPinIs: 'ستاسو نوی پین:',
    doNotSharePin: ' خپل پین له هیچا سره مه شریکوئ',
    pinInstructions: ' تاسو اوس کولی شئ دا پین د هر ټرانزکشن یا وتلو لپاره وکاروئ چې پین ته اړتیا لري.',
    alreadySetPin: 'داسې معلومیږې چې ستاسو پین له وخته تنظیم شوی دی!',
    accountLoginNeededForPinReset: ' مهرباني وکړئ خپل ګیف کارډ اکونټ ته ننوځئ ترڅو خپل پین بیا تنظیم کړئ یا د پیرودونکي خدمت سره اړیکه ونیسئ',
    oops: ' اوه!',
    expirationDateExample: 'MM/YY',
    yourCardIs: 'ستاسو کارت دی',
    cantSetPinWithThisStatus: 'تاسو نشئ کولی د دې حالت سره په کارت کې پن تنظیم کړئ.',
    reachOutToYourOrgOrGiveCard: 'مهرباني وکړئ خپل سازمان یا ته ورشئ',
    ifItsAMistake: 'که تاسو باور لرئ دا یوه تېروتنه ده.',

    // Lock Card
    unlockCardPromptHeader: ' ایا تاسو غواړئ دا کارډ خلاص کړئ؟',
    unlockCardPromptDetails: ' کارډ به سمدلاسه لیږد ته اجازه ورکړي او بیلانس به مصرف شي.',
    unlockCardButton: 'هو، دا کارډ خلاص کړئ.',
    unlockSuccessful: ' ستاسو کارډ په بریالیتوب سره پرانستل شو.',

    lockCardPromptHeader: ' ایا تاسو غواړئ چې دا کارډ بند کړئ؟',
    lockCardPromptDetails: ' کله چې کارډ بند وي، کارډ نور نشي کولی د ټرانزکشنو ترسره کولو او بیلانس ته لاسرسی ومومي.',
    lockCardButton: 'هو، دا کارډ بند کړئ.',
    lockSuccessfulHeader: 'ستاسو کارډ په بریالیتوب سره بند شو. ',
    lockSuccessfulDetails: ' له دې شیبې څخه، دا کارډ به د دې توان ونلري چې هیڅ بیلانس وکاروي یا هیڅ ډول معامله وکړي.',
    unlockHowTo: ' که تاسو غواړئ چې دا کارډ خلاص کړئ، د انفرادي کارډ پاڼې ته لاړ شئ او تاسو به په مستقیم ډول د کارډ پرانستلو پروسې ته ورشئ.',

    // Check Balance
    currentBalance: ' ستاسو اوسنی اکونټ',
    yourCardBalance: 'ستاسو د کارډ بیلانس:',
    contactSupportEmail: ' د هرې پوښتنې یا ستونزې لپاره، مهرباني وکړئ د پیرودونکي خدمت سره اړیکه ونیسئ.',

    // Create an Account
    createNewAccountSubheading: ' د اکونټ جوړولو دمخه، موږ باید ستاسو د ګیف کارډ معلومات تائید کړو. تاسو باید دا کار یوازې یو ځل ترسره کړئ!',
    wantToKnowMore: ' ایا تاسو غواړئ د خپل کارډ په اړه نور معلومات ترلاسه کړئ؟',
    createAPassword: 'یو پاسورډ جوړ کړئ',
    passwordRequirements: ' د پاسورډ قواعد: لږترلږه ۸ حروف، په شمول د ۱ شمیرې او ۱ ځانګړي حرف.',
    confirm: 'تائید یې کړئ',
    confirmPassword: 'پاسورډ تائید کړئ',
    alreadyHaveAnAccount: ' ایا تاسو اکونټ لرئ؟ دننه شئ.',
    accountAlreadyExistsError: ' اوه! د دې بریښنالیک سره یو اکونټ لا دمخه جوړ شوی دی.',
    cardAlreadyLinkedError: ' دا کارډ لا دمخه د بیل اکونټ سره تړلی دی.',
    accountCreationError: ' له بده مرغه، د اکونټ په جوړولو کې تېروتنه وشوه.',
    error: 'تیروتنه',
    tryAgainMessage: 'مهرباني وکړئ بیا هڅه وکړئ یا د پیرودونکي خدمت سره اړیکه ونیسئ',
    passwordMismatchError: ' پاسورډونه باید سمون ولري.',
    passwordInvalidError: ' پاسورډ باید لږترلږه ۸ حروف، په شمول د ۱ شمیرې او ۱ ځانګړي حرف ولري.',
    welcome: 'ښه راغلاست!',
    invalidTokenPage: ' بخښنه غواړو، خو لینک غلط دی! مهرباني وکړئ په هغه لینک کلیک وکړئ چې موږ تاسو ته په بریښنالیک کې لیږلی تر څو یو اکونټ جوړ کړئ.',
    createAccountToAccessVirtualCard: ' خپل مجازی کارډ ته د لاسرسي لپاره یو اکونټ جوړ کړئ.',
    alreadyHaveAccount: 'آیا اکونټ لرئ؟',
    firstName: 'لومړۍ نوم',
    lastName: 'کورنۍ نوم',
    firstAndLastName: 'مهربانې وکړئ خپل لومړنۍ نوم او د کورنۍ نوم ولیکئ',
    phoneNumber: 'د تلیفون شمیره',
    createAcctToViewPortal: ' د خپل سازمان پورټل لیدلو لپاره یو اکونټ جوړ کړئ.',
    yourEmailAddress: ' ستاسو د بریښنالیک پته',
    haventMetPasswordRequirements: ' تاسو د پاسورډ ټولې اړتیاوې نه دي پوره کړي.',
    createProfile: ' پروفایل جوړ کړئ',
    creatingUser: 'یو یوزر جوړ کړئ',
    invalidEmail: 'ناسم بریښنالیک',
    invalidPhoneNumber: 'د تلیفون شمیره ناسمه ده.',
    phoneRequirements: ' باید ۱۰ عددونه ولري، پرته له ډش',
    createAccountSubheading: ' راځئ چې لومړني معلومات راټول کړو!',
    enterMobilePhoneNumber: 'مهرباني وکړئ خپل ګرځنده شمیره دننه کړئ.',
    requirements: 'مقررات:',
    atLeast8Chars: 'لږترلږه ۸ توري وي •',
    oneNumber: '۱ عدد وي •',
    oneSpecialCharacter: ' يو ځانګړی حرف•',
    passwordsMatch: 'پاسورډونه سره سمون خوري •',
    alreadyHaveAdminAccount: ' ایا تاسو دمخه د ګیف کارډ مدیریت اکونټ لرئ؟',

    // Cardholder Portal
    notificationFeatureAnnouncement: ' تاسو د خپلو وروستیو ټرانزکشنو په اړه د بریښنالیک خبرتیا تر لاسه کوئ. د نورو معلوماتو لپاره، د خبرتیا تڼۍ کیکاږئ.',
    cardActivityHistory: ' د کارډ د فعالیت تاریخچه',
    transactions: 'ټرانزکشنونه',
    loadHistory: 'تاریخچه د خلاصیدو په حال کې ده',
    date: 'نيټه',
    transaction: 'ټرانزکشن',
    merchantCategory: 'د سوداګرو کټګوري ',
    amount: ' مقدار',
    timeLoaded: 'وخت د تیریدو په حال کې دی',
    amountLoaded: 'مقدار د پرانستلو په حال کې',
    lockCard: 'کارډ بند کړئ',
    unlockCard: 'کارډ پرانیزئ',
    home: 'لومړۍ پاڼه',
    emailNotificationsHeader: 'د بریښنالیک خبرتیاوې',
    emailNotificationsSubheader: ' ستاسو په کارډ کې د هر څه لپاره د بریښنالیک خبرتیا تر لاسه کړئ. تاسو کولی شئ دا خبرتیاوې هر وخت غیر فعاله کړئ.',
    notifications: 'خبرتیاوې',
    recentTransactions: 'وروستۍ ټرانزکشنې ',
    recentTransactionsSubheader: ' د لګښتونو نظارت او د درغلیو مخنیوي لپاره د خپلو وروستیو ټرانزکشنو په اړه خبرتیاوې ترلاسه کړئ.',
    cardDeclines: 'کارډ رد شو',
    cardDeclinesSubheader: ' د کارډ ردیدو په صورت کې به تاسو خبرتیا ترلاسه کړئ او په هغې کې به واضحه شوي وي چې ستاسو کارډ ولې رد شو.',
    cardLoads: 'کارډ کې پیسې اضافه کیږې',
    cardLoadsSubheader: ' کله چې پیسې ستاسو کارډ کې اضافه شي نو خبرتیا ترلاسه کړئ.',
    sendingTo: 'لېږل: ',
    saving: ' د خوندي کیدو په حال کې...',
    save: 'خوندي',
    cardCanOnlyBeUsedAt: 'ستاسو برنامه یوازې دا کارت اجازه ورکوي چې په لاندې سوداګرو کې وکارول شي:',
    moreInfoAboutMerchantRestrictions: 'د سوداګرو محدودیتونو په اړه د نورو معلوماتو لپاره، مهرباني وکړئ مستقیم خپل پروګرام سره اړیکه ونیسئ. GiveCard د دې محدودیتونو لپاره مسؤل نه دی.',


    // Transaction Table
    pending: 'په انتظار کې',
    pendingPurchases: 'پاتې پیرودونه',
    pendingPurchasesDescription: ' پاتې پیرودونه هغه لګښتونه دي چې د پلورونکي لخوا تصویب شوي مګر لاهم ندي خپاره شوي ، معمولا په ۵ ورځو کې دا ستاسو په اوسني بیلانس کې شاملې شي.',
    cleared: ' پاک شوی',
    clearedPurchases: 'پاک شوي پیرودونه',
    clearedPurchasesDescription: ' پاک شوي پیرودونه د پلورونکي لخوا تایید شوي او منل شوي وي.',
    loadingTxs: ' ټرانزکشنونه د پرانستل کیدو په حال کې دي.',

    // Full Card Details
    errorGettingCardDetails: ' د کارډ توضیحاتو په ترلاسه کولو کې تېروتنه.',
    pleaseRefreshThePage: 'مهربانې وکړئ پاڼه تازه کړئ.',
    cardNumber: 'د کارډ شمیره',
    howDoIUseMyVirtualCard: 'زه څنګه کولی شم خپل مجازی کارډ وکاروم؟',
    usingAVirtualCard: 'د مجازی کارډ کارول',
    clickThe: 'کلیک وکړئ',
    toSeeFullCardDetails: 'تر څو د کارډ بشپړ جزئیات وګورئ',
    cardCanBeUsedAnywhere: ' دا کارډ هر هغه چیرې کارول کیدی شي چې د پری پیډ ماسټر کارډونه مني.',
    cardInstructions: ' په دې پورټل کې ښودل شوي معلومات کاپي او پیسټ کولو سره دا کارډ آنلاین وکاروئ',
    digitalWalletInstructions: 'خپل کارډ ',
    digitalWalletInstructions2: ' خپل موبايل کې د کارډ جزئياتو دننه کولو سره کارډ اضافه کړئ. نور لارښوونې په لاندې لینک کې شتون لري.',
    orLowercase: 'یا',
    billingAddress: ' که تاسو د خپل پیرود لپاره د بیل ادرس ته اړتیا لرئ، مهرباني وکړئ لاندې ادرس وکاروئ:',
    needSupport: ' ایا تاسو ملاتړ ته اړتیا لرئ؟',
    teamReadyToHelp: ' زموږ د ملاتړ ټیم مرستې ته چمتو دی',
    pleaseEmail: ' مهرباني وکړئ موږ ته بریښنالیک واستوئ',
    call: 'زنګ ووهئ',

    // Billing Address
    billingAddressTitle: ' د بیل ادرس',
    yourBillingAddress: 'د آنلاین پیرود لپاره ستاسو د بیل ادرس',
    pleaseUseThis: ' تاسو دغه د بیل ادرس د آنلاین ټرانزکشنو پر مهال کارولی شئ.',

    // Error Modal
    whoops: 'اوه',
    encounteredAnError: ' بخښنه غواړو، موږ د یوې تېروتنې سره مخ شو.',
    tryAgain: 'بیا هڅه وکړئ',
    ifProblemPersists: ' که دا ستونزه دوام ومومي، مهرباني وکړئ د خپل ګیف کارډ مخاطب سره اړیکه ونیسئ.',
    forDevelopers: ' د پراختیا ورکونکو (ډیویلپرانو) لپاره ',
  },

  far: {
    // Platform Home Page
    for: 'برای',
    cardholders: 'دارندگان کارت',
    admins: 'مدیران',
    cardholdersLandingSubheading: 'آیا شما یک گیف کارت را دریافت کرده‌اید و به دنبال اطلاعات بیشتر هستید؟',
    adminLandingSubheading: 'به داشبورد گیف کارت خود وارد شوید!',

    cardholderWelcome: 'امید اینجا آغاز می‌شود',
    cardholderSubWelcome: 'ما خوشحال استیم که شما گیف کارت خود را دریافت کرده‌اید.',
    memberSubWelcome: 'ما خوشحال استیم که شما به ما یکجای می‌شوید.',

    // CardholderLogin Component
    login: 'وارد شوید',
    enterAValidEmail: 'لطفاً یک ایمیل معتبر را وارد کنید.',
    or: 'یا',
    exploreCard: 'میخواهید بیشتر درباره کارت خود بدانید؟',
    createAnAccount: 'یک حساب کاربری را ایجاد کنید',
    email: 'ایمیل',
    emailOrPhone: 'ایمیل یا تلفن',
    password: 'پاسورد',
    welcomeBack: 'دوباره خوش آمدید!',
    enterPassword: 'لطفاً پاسورد خود را وارد کنید',
    enterOneTimePassword: 'لطفا کد 6 رقمی را وارد کنید',
    codeSentTo: 'کد ارسال شده به',
    passwordReminder: 'به یاد داشته باشید که پاسورد شما دارای ۸ حروف شامل ۱ عدد و ۱ حرف خاص باشد',
    forgotYourPassword: 'آیا پاسورد خود را فراموش کرده‌اید؟ دوباره آن را.',
    here: 'در اینجا تنظیم کنید',
    resetYourPassword: 'پاسورد خود را دوباره تنظیم کنید',
    sendResetPasswordLink: 'ارسال لینک دوباره تنظیم پاسورد',
    resetLinkInstructions: ' یک لینک برای دوباره تنظیم کردن پاسورد به شما ارسال شده است',
    checkSpamFolder: 'اگر لینک را نمی‌بینید، لطفاً فولدر سپم خود را ببنید.',
    loginAgain: 'دوباره وارد شوید',
    dontHaveAccount: 'حساب کاربری ندارید؟ لطفا از طریق لینکی که به ایمیل شما ارسال شده است یک حساب کاربری ایجاد کنید.',
    verifyPhoneNumber: 'شماره تلفن خود را تأیید کنید',
    sendOTP: 'ارسال کد تایید',
    verifyOTP: 'کد تأیید را تأیید کنید',

    // QuickResources Component Variables
    quickResources: 'منابع سریع',
    checkBalance: 'بیلانس را ببندید',
    findAnATM: 'یک ای تی ام را پیدا کنید',
    setPin: 'یک رمز عبور را تنظیم کنید',

    // Frequently Asked Questions
    faqHeader: 'پرسش‌های متداول',
    faqQuestion1: 'آیا باید کارت خود را قبل از استفاده فعال کنم؟',
    faqAnswer1: ' نه، شما نیازی به انجام هیچ کاری قبل از شروع استفاده از کارت خود ندارید. تا زمانی که موجودی در کارت شما وجود داشته باشد، می‌توانید از کارت خود استفاده کنید.',
    faqQuestion2: 'کارت جدیدم را کجا می‌توانم استفاده کنم؟',
    faqAnswer2: ' هر جایی که کارتهای دبیت پذیرفته شوند، می‌توانید از کارت جدید خود استفاده کنید، به شرطی که برنامه شما هیچ قوانین خرج کردنی نداشته باشد. در برخی از فروشگاه‌ها که احراز هویت الزامی است، ممکن است محدودیت‌هایی وجود داشته باشد. اگر سازمان شما قوانین خرج کردنی دارد، قبل از توزیع کارت به شما، به شما اطلاع خواهند داد.',
    faqQuestion3: 'آیا می‌توانم از کارت خود در یک دستگاه خودپرداز برای برداشت وجه نقد استفاده کنم؟',
    faqAnswer3: ' این بستگی به این دارد که آیا سازمان شما اجازه برداشت از خودپرداز را می‌دهد یا خیر. لطفاً با سازمان خود تماس بگیرید تا بفهمید. اگر اجازه داده شده باشد، نیاز دارید ابتدا یک رمز عبور تعیین کنید. با کلیک بر روی دکمه "تعیین رمز رمز عبور" بالا، می‌توانید فرایند تنظیم رمز عبور خود را آغاز کنید.',
    faqQuestion4: 'چگونه می‌توانم رمز عبور خود را تنظیم کنم؟',
    faqAnswer4: ' با کلیک بر روی دکمه "تعیین رمز عبور"، می‌توانید فرایند تنظیم رمز عبور خود را آغاز کنید. کارت خود را در دست داشته باشید و دستورالعمل‌ها را دنبال کنید.',

    faqQuestion5: ' چگونه می‌توانم رمز عبور خود را دوباره تنظیم کنم؟',
    faqAnswer5: ' با کلیک بر روی دکمه "تنظیم رمز عبور"، می‌توانید فرآیند تنظیم مجدد رمز عبور خود را آغاز کنید. کارت خود را در دسترس داشته باشید. دستورالعمل‌ها را دنبال کنید. آنها مشابه بار اولیه‌ای هستند که رمز عبور را تنظیم کردید.',

    faqQuestion6: ' کارت خود را گم کردم یا کسی آن را دزدید. چه باید کنم؟',
    faqAnswer6: ' با خدمات مشتریان گیف کارت تماس بگیرید (ایمیل زیر و یا با شماره ۰۹۵۵-۵۲۹-۹۰۸ تماس بگیرید). گیف کارت فرآیند قفل کردن کارت و ارسال یک کارت جدید برای شما را انجام خواهد داد.',
    faqQuestion7: ' کارت من رد شد. چرا؟',
    faqAnswer7: ' چندین دلیل ممکن است باعث رد شدن یک کارت شود. مشکل رایج ‌تر در صورت نبود بیلانس کافی است. مشکلات دیگر شامل کلیک کردن لغو هنگامی که درخواست رمز عبور را می‌کند، خطا در دستگاه خواننده کارت یا تنظیم قوانین مصرف توسط سازمان‌ها است. با سازمان خود تماس بگیرید تا اطلاعات لازم را بدست آورید. فروشگاه‌های دیگر ممکن است براساس قوانین خود در مورد ترانزکشن‌ها رد کنند. می‌توانید این مشکل را با تماس مستقیم با فروشگاه حل کنید و اگر ترانزکشن شما توسط صادرکننده کارت تأیید شده است، آنها ممکن است قادر به ارائه یک تأیید اضافی باشند.',

    faqQuestion8: ' کدام آدرس صورتحساب باید برای خریدهای آنلاین استفاده کنم؟',
    faqAnswer8: ' اگر شما یک کارت فیزیکی دارید، لطفاً یک حساب کاربری بسازید و در رمز عبورل دارنده کارت روی "آدرس صورتحساب" کلیک کنید یا با خدمات مشتریان گیف کارت تماس بگیرید. اگر شما یک کارت مجازی دارید، آدرس صورتحساب ۱۲۳ سرک گیف کارت، شهر نیویارک، ایالت نیویارک، ۱۰۰۰۱ است.',
    faqQuestion9: ' با چه کسی باید درباره کمبود بیلانس تماس بگیرم؟',
    faqAnswer9: ' با سازمانی که به شما کارت را داده است تماس بگیرید. این سازمان قادر خواهد بود به شما اطلاعاتی در مورد بیلانس حسابتان را ارائه کند.',

    // Additional Support Footer
    furtherQuestionsInfo: ' برای سوالات و مشکلات بیشتر، لطفاً با ما تماس بگیرید ',
    supportEmailAndPhoneNumber: ' یا با شماره ۹۰۸-۵۲۹-۰۹۵۵ تماس بگیرید.',
    customerSupportHours: ' خدمات مشتریان از ساعت ۹ صبح تا ۶ بعدازظهر به وقت اي.ایس.تي از دوشنبه تا جمعه در دسترس است*.',
    holidaysSupportException: '* تعطیلی‌ها بر این ساعات تأثیر خواهند گذاشت',

    // Card Details
    confirmCardInformation: 'تأیید اطلاعات کارت',
    last6Digits: 'شش رقم آخر شماره کارت',
    organizationName: ' نام سازمان در پشت کارت',
    expirationDate: 'تاریخ انقضای کارت',
    invalidError: 'معلومات کارت نامعتبر است. لطفاً دوباره امتحان کنید',

    // Set Pin
    last6Error: 'قالب ورودی شش رقم آخر نامعتبر است',
    expirationError: 'قالب ورودی تاریخ انقضا نامعتبر است',
    setNewPin: 'یک رمز عبور نو را تنظیم کنید',
    confirmNewPin: 'رمز عبور نو را تائید کنید',
    pinLengthError: 'رمز عبور باید حد اقل ۴ رقم باشد.',
    pinMatchingError: 'رمز عبور‌ها مطابقت ندارند.',
    pinSetSuccess: 'موفقیت آمیز!',
    yourNewPinIs: 'رمز عبور نو شما:',
    doNotSharePin: 'رمز عبور خود را با هیچ کسی شریک نسازید',
    pinInstructions: 'اکنون می‌توانید این رمز عبور را برای هر معامله (ترانزکشن) یا برداشتی که از شما رمز عبور درخواست می‌کند، استفاده کنید.',
    alreadySetPin: 'معلوم می شود که رمز عبور شما قبلا تنظیم شده!',
    accountLoginNeededForPinReset: ' لطفاً وارد حساب کاربری گیف کارت خود شوید تا رمز عبور خود را باز تنظیم کنید یا خدمات مشتریان تماس بگیرید',
    oops: ' اوه!',
    expirationDateExample: 'MM/YY',
    yourCardIs: 'کارت شماست',
    cantSetPinWithThisStatus: 'نمی توانید روی کارتی با این وضعیت پین تنظیم کنید.',
    reachOutToYourOrgOrGiveCard: 'لطفا با سازمان خود تماس بگیرید یا به',
    ifItsAMistake: 'اگر معتقدید این یک اشتباه است',

    // Lock Card
    unlockCardPromptHeader: 'آیا شما می خواهید که این کارت را باز کنید؟',
    unlockCardPromptDetails: ' کارت به طور فوری اجازه می‌دهد تا ترانزکشن ها ادامه پیدا کند و بیلانس مصرف شود.',
    unlockCardButton: 'بلی، این کارت را باز کنید.',
    unlockSuccessful: 'کارت شما با موفقیت باز شد.',

    lockCardPromptHeader: 'آیا مطمئن هستید که می‌خواهید این کارت را قفل کنید؟',
    lockCardPromptDetails: 'وقتیکی که کارت قفل باشد، کارت دیگر قادر به انجام ترانزکشن‌ها و دسترسی به بیلانس نمی‌باشد',
    lockCardButton: 'بلی،‌ این کارت را قفل کنید.',
    lockSuccessfulHeader: 'کارت شما با موفقیت قفل شد.',
    lockSuccessfulDetails: ' از این لحظه به بعد، این کارت قادر به استفاده از هیچ گونه بیلانس یا انجام هیچ ترانزکشنی نخواهد بود.',
    unlockHowTo: ' اگر میخواهید این کارت را باز کنید، نشانگر را بر روی وضعیت در صفحه کارت فردی بگذارید و خودکار به شما در فرایند باز کردن کارت هدایت می‌شود.',

    // Check Balance
    currentBalance: 'بیلانس فعلی',
    yourCardBalance: 'بیلانس کارت شما:',
    contactSupportEmail: 'برای هر سؤال یا مشکل، لطفاً با خدمات مشتریان تماس بگیرید.',

    // Create an Account
    createNewAccountSubheading: ' قبل از ایجاد حساب کاربری، ما باید جزئیات گیف کارت شما را تأیید کنیم. شما تنها باید این کار را یکبار انجام دهید!',
    wantToKnowMore: 'آیا میخواهید اطلاعات بیشتری درباره کارت خود بدانید؟',
    createAPassword: 'یک پاسورد را ایجاد کنید.',
    passwordRequirements: 'مقررات پاسورد: حداقل ۸ حروف، شامل ۱ عدد و ۱ حرف خاص.',
    confirm: 'تائید کنید',
    confirmPassword: 'پاسورد را تائید کنید',
    alreadyHaveAnAccount: ' آیا حساب کاربری دارید؟ وارد شوید. ',
    accountAlreadyExistsError: 'اوه! حسابی با این آدرس ایمیل قبلاً ایجاد شده است.',
    cardAlreadyLinkedError: 'این کارت قبلاً با یک حساب دیگر مرتبط شده است.',
    accountCreationError: 'متأسفانه در ایجاد حساب کاربری خطایی رخ داده است.',
    error: 'اشتباه',
    tryAgainMessage: 'لطفاً دوباره امتحان کنید یا با خدمات مشتریان تماس بگیرید',
    passwordMismatchError: 'پاسورد ها باید مطابقت داشته باشند.',
    passwordInvalidError: 'پاسورد باید شامل ۸ حروف باشد که حداقل ۱ عدد و یک حرف خاص داشته باشد.',
    welcome: 'خوش آمدید!',
    invalidTokenPage: ' ببخشید، لینک اشتباه است! لطفاً بر روی لینک که به شما در ایمیل ارسال کرده‌ایم کلیک کنید تا یک حساب کاربری بسازید.',
    createAccountToAccessVirtualCard: 'برای دسترسی به کارت مجازی خود، یک حساب کاربری بسازید.',
    alreadyHaveAccount: 'آیا حساب کاربری دارید؟',
    firstName: 'اسم اول',
    lastName: 'نام خانوادگی',
    firstAndLastName: 'لطفاً اسم اول و نام خانوادگی خود را وارد کنید',
    phoneNumber: 'نمبر تلیفون',
    createAcctToViewPortal: 'برای مشاهده پورتال سازمان خود، یک حساب کاربری بسازید.',
    yourEmailAddress: 'ایمیل ادرس شما',
    haventMetPasswordRequirements: 'شما تمامی مقررات پاسورد را برآورده نکرده‌اید.',
    createProfile: 'پروفایل را ایجاد کنید',
    creatingUser: 'یوزر را ایجاد کنید',
    invalidEmail: 'ایمیل نامعتبر',
    invalidPhoneNumber: 'شماره تلفن نامعتبر است.',
    phoneRequirements: 'باید 10 رقم داشته باشد، بدون خط تیره (دش)',
    createAccountSubheading: 'بیایید اطلاعات اساسی را جمع‌آوری کنیم!',
    enterMobilePhoneNumber: 'لطفاً شماره تلفن همراه خود را وارد کنید.',
    requirements: 'مقررات:',
    atLeast8Chars: 'حداقل ۸ حروف باشد •',
    oneNumber: '۱ عدد باشد •',
    oneSpecialCharacter: ' یک خرف خاص•',
    passwordsMatch: 'پاسورد ها مطابقت دارند •',
    alreadyHaveAdminAccount: 'حساب مدیریت گیف کارت را زا قبل دارید؟',

    // Cardholder Portal
    notificationFeatureAnnouncement: ' امکان دریافت اعلانات از طریق ایمیل درباره ترانزکشن‌های اخیر شما فعال شده است. برای کسب اطلاعات بیشتر، روی دکمه اعلانات کلیک کنید.',
    cardActivityHistory: 'تاریخچه فعالیت کارت',
    transactions: 'ترانزکشن ها',
    loadHistory: 'تاریخچه در حال باز شدن است',
    date: 'تاریخ',
    transaction: 'ترانزکشن',
    merchantCategory: 'دسته ‌بندی فروشنده',
    amount: 'مقدار',
    timeLoaded: 'وقت تیر میشه',
    amountLoaded: 'مقدار در حال باز شدن است',
    lockCard: 'کارت را قفل کنید',
    unlockCard: 'کارت را باز کنید',
    home: 'صفحه اول',
    emailNotificationsHeader: 'اعلانات ایمیلی',
    emailNotificationsSubheader1: 'درخواست ارسال اعلانات به ایمیل خود را درباره هر فعالیتی که در کارت رخ می‌دهد، بدهید.',
    emailNotificationsSubheader2: '. شما می‌توانید در هر زمان این اعلانات را غیرفعال کنید.',
    notifications: 'اطلاعیه',
    recentTransactions: 'ترانزکشن‌های اخیر',
    recentTransactionsSubheader: ' برای رصد هزینه‌ها و جلوگیری از تقلب، اطلاعیه های را درباره ترانزکشن‌های اخیر خود دریافت کنید.',
    cardDeclines: 'کارت رد شد',
    cardDeclinesSubheader: 'اطلاعیه را دریافت کنید هنگامی که کارت شما رد می‌شود تا دلیل آن را بفهمید.',
    cardLoads: 'Card Loads',
    cardLoadsSubheader: 'اطلاعیه را دریافت کنید هنگامی که پیسه به کارت شما اضافه می‌شود.',
    sendingTo: 'ارسال به: ',
    saving: 'در حال سیف شدن...',
    save: 'سیف',
    cardCanOnlyBeUsedAt: 'برنامه شما فقط اجازه استفاده از این کارت را در تاجران زیر می دهد:',
    moreInfoAboutMerchantRestrictions: 'برای اطلاعات بیشتر در مورد محدودیت های تجاری، لطفاً مستقیماً با برنامه خود تماس بگیرید. GiveCard مسئولیتی در قبال این محدودیت ها ندارد.',

    // Transaction Table
    pending: 'در انتظار',
    pendingPurchases: 'خریدهای که در انتظار',
    pendingPurchasesDescription: ' خریدهای در انتظار، هزینه‌هایی هستند که توسط فروشنده تأیید شده اما هنوز منتشر نشده‌اند و معمولاً در عرض ۵ روز منتشر می‌شوند. آنها در بیلانس فعلی شما شامل است.',
    cleared: 'پاک شد',
    clearedPurchases: 'خریدهای پاک شده',
    clearedPurchasesDescription: 'خریدهای پاک شده توسط فروشنده تأیید شده و تصدیق شده‌اند.',
    loadingTxs: 'ترانزکشن ها در حال بازشدن است.',

    // Full Card Details
    errorGettingCardDetails: 'بازیابی جزئیات کارت با خطا مواجه شد.',
    pleaseRefreshThePage: 'لطفاً صفحه را تازه کنید.',
    cardNumber: 'شماره کارت',
    howDoIUseMyVirtualCard: 'چطور می توانم کارت مجازي خود را استفاده کنم؟',
    usingAVirtualCard: 'استفاده از کارت مجازي',
    clickThe: 'کلیک کنید',
    toSeeFullCardDetails: 'برای مشاهده جزئیات کامل کارت',
    cardCanBeUsedAnywhere: 'این کارت می‌تواند در هر جایی استفاده شود که کارت پیش‌پرداخت ماسترکارت را قبول کند.',
    cardInstructions: 'با کاپی و پیست اطلاعات نشان داده شده در این پورتال، از این کارت به صورت آنلاین استفاده کنید',
    digitalWalletInstructions: 'کارت خود را',
    digitalWalletInstructions2: ' با وارد کردن جزئیات کارت در موبایل خود، کارت را اضافه کنید. دستورالعمل‌های بیشتر در لینک مرتبط قابل دسترسی است.',
    orLowercase: 'یا',
    billingAddress: 'اگر برای خرید نیاز به آدرس صورتحساب است، لطفا از آدرس زیر استفاده کنید:',
    needSupport: 'به پیشتبانی ضرورت دارید؟',
    teamReadyToHelp: 'تیم پشتیبانی ما آماده است که کمک کند',
    pleaseEmail: ' لطفاً به ما ایمیل بفرستید',
    call: 'زنگ بزنید',

    // Billing Address
    billingAddressTitle: 'آدرس صورتحساب',
    yourBillingAddress: 'آدرس صورتحساب شما برای خریدهای آنلاین',
    pleaseUseThis: 'لطفاً هر بار که در حال انجام ترانزکشن آنلاین هستید، از این آدرس صورتحساب استفاده کنید.',

    // Error Modal
    whoops: 'اوه',
    encounteredAnError: 'ببخشید، با خطا مواجه شدیم.',
    tryAgain: 'دوباره امتحان کنید',
    ifProblemPersists: 'اگر این مشکل ادامه پیدا کند، لطفاً با مخاطب گیف کارت خود تماس بگیرید.',
    forDevelopers: 'برای توسعه ‌دهندگان (دیویلپر ها)',

  },

  ar: {
    // Platform Home Page
    for: 'لـ',
    cardholders: 'حاملي البطاقات',
    admins: 'المُدراء',
    cardholdersLandingSubheading: 'هل تلّقيت بطاقتك وتود معرفة المزيد من التفاصيل؟',
    adminLandingSubheading: 'سجّل الدخول إلى لوحة تحكم بطاقتك الخاصّة بك',

    cardholderWelcome: 'هُنا؛ يبدأ الأمل',
    cardholderSubWelcome: 'سُعداء بتلقيك بطاقتك الخاصة بك',
    memberSubWelcome: 'يُسعدنا انضمامُك إلينا.',

    // CardholderLogin Component
    login: 'تسجيل الدخول',
    enterAValidEmail: 'أدخل بريدًا إلكترونيًا صحيحًا',
    or: 'أو',
    exploreCard: 'أتود معرفة المزيد عن بطاقتك؟',
    createAnAccount: 'إنشاء حساب',
    email: 'البريد الالكتروني',
    emailOrPhone: 'البريد الإلكتروني أو الهاتف',
    password: 'كلمة المرور',
    welcomeBack: 'مرحبًا بعودتك!',
    enterPassword: 'من فضلك أدخل كلمة المرور الخاصّة بك!',
    enterOneTimePassword: 'الرجاء إدخال الرمز المكون من 6 أرقام',
    codeSentTo: 'تم إرسال الرمز إلى',
    passwordReminder: 'تذكير. ثمانية أحرف بما في ذلك رقم واحد ورمز خاص واحد',
    forgotYourPassword: 'هل نسيت كلمة المرور الخاصة بك؟ قم بإعادة تعيينها ',
    here: 'هنا',
    resetYourPassword: 'قُم بإعادة تعيين كلمة المرور الخاصة بك؟',
    sendResetPasswordLink: 'إرسال رابط إعادة تعيين كلمة المرور',
    resetLinkInstructions: 'تم إرسال رابط لإعادة تعيين كلمة المرور إلى',
    checkSpamFolder: 'إن لم تجد الرابط، فتحقق من مُجلد البريد العشوائي',
    loginAgain: 'تسجيل الدخول مرةً أخرى',
    dontHaveAccount: 'ليس لديك حساب؟ يرجى إنشاء حساب عبر الرابط الذي تم إرساله إلى بريدك الإلكتروني.',
    verifyPhoneNumber: 'التحقق من رقم هاتفك',
    sendOTP: 'إرسال رمز التحقق',
    verifyOTP: 'التحقق من رمز التحقق',

    // QuickResources Component Variables
    quickResources: 'حلول سريعة',
    checkBalance: 'الإستعلام عن الرصيد',
    findAnATM: 'البحث عن ماكينة صرّاف آلي',
    setPin: 'تحديد رقم التعريف الشخصي',

    // Frequently Asked Questions
    faqHeader: 'الأسئلة الشائعة',
    faqQuestion1: 'هل يتوجّب عليّ تفعيل بطاقتي قبل استخدامها؟',
    faqAnswer1: 'لا. لايلزمك فعلُ أي شئ قبل استخدامك للبطاقة. فطالما أن لديك رصيدًا في بطاقتك، فيُمكنك استخدامها متى وكيف تشاء.',
    faqQuestion2: 'أين يُمكنني استخدام بطاقتي؟',
    faqAnswer2: 'في أي مكان يقبل بطاقات الخصم، طالما أن برنامجك ليس لديه أي قواعد للإنفاق. قد تطبق القيود أيضًا في المتاجر التي تتطلب بطاقة الهوية. إذا كانت مؤسستك لديها قواعد للإنفاق، فستُخبرك بذلك قبل إعطاؤك البطاقة.',
    faqQuestion3: 'أيمكنني استخدام بطاقتي لسحب الأموال من ماكينة الصرّاف الآلي؟',
    faqAnswer3: "يعتمد هذا الأمر على مؤسستك، إذا ما كانت تدعم عمليات السحب النقدي من ماكينات الصرّاف الآلي أم لا.  فضلًا تواصل مع مؤسستك للمزيد من المعلومات حول هذا الأمر. إن كانت مؤسستك تسمح بالسحب النقدي، فستحتاج لتحديد رقم التعريف الشخصي أولًا. قُم بتحديد رقم التعريف الشخصي الخاص بك بالضغط على الزرّ أعلاه 'حدد رقم التعريف الشخصي",
    faqQuestion4: 'كيف يُمكنني تحديد رقم التعريف الشخصي؟',
    faqAnswer4: 'بالضغط على الزرّ أعلاه "حدد رقم التعريف الشخصي", يُمكنك البدء في عملية تحديد رقم التعريف الشخصي الخاص بك احتفظ بالبطاقة في يدك. اتبع التعليمات',
    faqQuestion5: 'كيف يُمكنني إعادة تحديد رقم التعريف الشخصي؟',
    faqAnswer5: 'بالضغط على الزرّ أعلاه "حدد رقم التعريف الشخصي", يُمكنك البدء في عملية إعادة تحديد رقم التعريف الشخصي الخاص بك. احتفظ بالبطاقة في يدك. اتبع التعليمات. ستظهر التعليمات كما رأيتها للمرة الأولى التي قُمت فيها بتحدد رقم التعريف الشخصي.',
    faqQuestion6: 'إذا فقدت بطاقتي، أو سُرِقت منّي.  مالذي ينبغي عليّ فعله؟',
    faqAnswer6: 'اتصل بفريق الدعم لدينا على البريد الإلكتروني أدناه، أو رقم الهاتف المذكور هنا. سنقوم بإلغاء تنشيط بطاقتك ونرسل لك بطاقة جديدة. 9085290955',
    faqQuestion7: 'تم رفض بطاقتي. لماذا؟',
    faqAnswer7: 'أسبابٌ عديدة ربُما قد تقف وراء رفض بطاقتك. رُبما أكثرها شيوعاً عدم وجود رصيد كافٍ في بطاقتك. المُشكلات الأخرى التي رُبّما يواجهها المُستخدم هي ضغط زرّ الإلغاء عندما يتطلب الأمر رقم التعريف الشخصي. كذلك عندما يكونُ هناك خطأ في قارئ البطاقاة، أو عندما تضع المؤسسة التي تتبعها قيودًا على الإنفاق من خلال البطاقة. فضلًا تواصل مع مؤسستك للمزيد من المعلومات حول هذا الأمر. يمكن كذلك لبعض التجار رفض البطاقة بناءً على قواعد قبول ورفض المعاملات الخاصة بهم. يُمكنك حلّ هذه المُشكلة بالتواصل مع التاجر مُباشرة، حينها رُبما يمكنه تقديمَ عرضٍ لك إذا قمت بتأكيد موافقة جهة إصدار بطاقتك على المعاملة. ',
    faqQuestion8: 'ماهو عنوان الذي ينبغي أن استخدمه كي تصل إليه فواتير الشراء عبر الإنترنت؟',
    faqAnswer8: 'إذا كان لديك بطاقة فعلية، فيرجى إنشاء حساب بالنقر فوق "عنوان إرسال الفواتير" داخل التطبيق أو الاتصال بفريق الدعم لدينا. إذا كانت لديك بطاقة افتراضية، فإن عنوان إرسال الفواتير الخاص بك هو التالي 123 GiveCard Way, New York, NY, 10001',
    faqQuestion9: 'بمن يُمكنني الإتصال في حالة عدم كفاية الأموال؟',
    faqAnswer9: 'تواصل مع المؤسسة التي أعطتك البطاقة. ستكون المؤسسة قادرة على إخبارك بشأن رصيدُكَ الماليّ.',

    // Additional Support Footer
    furtherQuestionsInfo: 'من فضلك لا تتردد في التواصل مع فريق الدعم في حالة ما إذا كان لديك أي أسئلة أو تواجه أي مُشكلات',
    supportEmailAndPhoneNumber: 'أو اتصل بالهاتف رقم 0955-529-908',
    customerSupportHours: 'خدمة العملاء مُتاحة في الأوقات التالية: من الإثنين للجمعة، من التاسعة صباحا وحتى السادسة مساًءًا وفقًا للتوقيت الشرقي لأمريكا الشمالية.',
    holidaysSupportException: '*تخضع هذه الأوقات للأجازات والمناسبات الرسمية',

    // Card Details
    confirmCardInformation: 'من فضلك قُم بتأكيد معلومات البطاقة',
    last6Digits: 'آخر ستة أرقام من رقم البطاقة',
    organizationName: 'اسم المؤسسة الموجود على الوجه الخلفي للبطاقة',
    expirationDate: 'تاريخ الإنتهاء',
    invalidError: 'معلومات البطاقة غير صحيحة. حاول مرًة أخرى',

    // Set Pin
    last6Error: 'تنسيق الأرقام الستة الأخيرة المُدخَلَة غير صحيح',
    expirationError: 'تنسيق تاريخ إنتهاء صلاحية البطاقة المُدخَلْ غير صحيح',
    setNewPin: 'تحديد رقم التعريف الشخصي الجديد',
    confirmNewPin: 'تأكيد رقم التعريف الشخصي الجديد',
    pinLengthError: 'رقم التعريف الشخصي لابُدّ أن يتكون من أربعة أحرف.',
    pinMatchingError: 'رقمي التعريف الشخصي غير مُتطابقين.',
    pinSetSuccess: 'تمّ تعيين رقم التعريف الشخصي بنجاح!',
    yourNewPinIs: 'رقم التعريف الشخصي الجديد هو:',
    doNotSharePin: 'لا تُشارك رقم التعريف الشخصي الخاص بك مع أي شخصٍ آخر.',
    pinInstructions: 'يمكنك الآن استخدام رقم التعريف الشخصي هذا لأي مُعاملة أو سحب نقدي يتطلب رقم التعريف الشخصي.',
    alreadySetPin: 'يبدو أنه قد تم بالفعل تعيين رقم التعريف الشخصي الخاص بك!',
    accountLoginNeededForPinReset: 'يُرجى تسجيل الدخول إلى حساب الخاص بك لإعادة تعيين رقم التعريف الشخصي أو قُم بالتواصل مع الدعم الفني عبر',
    oops: 'عفوًا',
    expirationDateExample: 'الشهر/السنة',
    yourCardIs: 'بطاقتك هي',
    cantSetPinWithThisStatus: 'لا يمكنك تعيين رقم تعريف شخصي على البطاقة بهذه الحالة.',
    reachOutToYourOrgOrGiveCard: 'يرجى التواصل مع مؤسستك أو',
    ifItsAMistake: 'إذا كنت تعتقد أن هذا خطأ.',

    // Lock Card
    unlockCardPromptHeader: 'هل ترغب في تفعيل هذه البطاقة؟',
    unlockCardPromptDetails: 'ستسمح البطاقة على الفور باستئناف المعاملات وإنفاق الأموال.',
    unlockCardButton: 'نعم، من فضلك قُمّ بتقعيل هذه البطاقة',
    unlockSuccessful: 'تم تفعيل بطاقتك بنجاح',

    lockCardPromptHeader: 'هل أنت مُتأكد من رغبتك في إيقاف هذه البطاقة؟',
    lockCardPromptDetails: 'بعد إيقاف البطاقة لن تتمكن من إجراء المعاملات المالية',
    lockCardButton: 'نعم، من فضلك قُمّ بإيقاف هذه البطاقة',
    lockSuccessfulHeader: 'تم إيقاف بطاقتك بنجاح',
    lockSuccessfulDetails: 'لن تتمكن من استخدام أي أموال أو إتمام أي معاملات من خلال هذه البطاقة اعتبارًا من هذه اللحظة.',
    unlockHowTo: 'إذا كنت ترغب في إعادة تفعيل هذه البطاقة، قم بالتمرير فوق الحالة في صفحة البطاقة وسوف يوجهك تلقائيًا خلال عملية التفعيل.',

    // Check Balance
    currentBalance: 'الرصيد الحالي',
    yourCardBalance: 'رصيد بطاقتك الحالي هو:',
    contactSupportEmail: 'من فضلك لا تتردد في التواصل مع فريق الدعم في حالة ما إذا كان لديك أي أسئلة أو تواجه أي مُشكلات',

    // Create an Account
    createNewAccountSubheading: 'قبل إنشاء الحساب، نودّ تأكيد بيانات بطاقة ​​حساب الخاصة بك. ستفعل هذا لمرةٍ واحدةٍ فقط',
    wantToKnowMore: 'أتود معرفة المزيد من المعلومات عن بطاقتك؟',
    createAPassword: 'أنشئ كلمة مرور',
    passwordRequirements: 'مُتطلبات إنشاء كلمة مرور: ثمانية أحرف بما في ذلك رقم واحد ورمز خاص واحد',
    confirm: 'تأكيد',
    confirmPassword: 'تأكيد كلمة المرور',
    alreadyHaveAnAccount: 'لديك حسابٌ بالفعل؟ تسجيل الدخول',
    accountAlreadyExistsError: 'عفوًا هُناك حساب بالفعل مُسّجل لدينا بنفس هذا البريد الإلكتروني.',
    cardAlreadyLinkedError: 'هذه البطاقة مُتصلة بالفعل بحسابٍ آخر',
    accountCreationError: 'خطأ في إنشاء الحساب.',
    error: 'خطأ',
    tryAgainMessage: 'من فضلك حاول مرةً أخرى، أو تواصل مع فريق الدعم',
    passwordMismatchError: 'لابُدّ أن تتطابق كلمتي المرور.',
    passwordInvalidError: 'لابُدّ أن تحتوي كلمة المرور على ثمانية أحرف بما في ذلك على الأقل رقم واحد ورمز خاص واحد',
    welcome: 'مرحبًا',
    invalidTokenPage: 'معذرةً، رابط خاطئ! من فضلك قم بالضغط على الرابط الذي أرسلناه إليك عبر بريدك الإلكتروني المُسّجل لدينا، لتتمكن من إنشاء الحساب',
    createAccountToAccessVirtualCard: 'أنشئ حسابًا لتتمكن من استخدام بطاقتك الإفتراضية!',
    alreadyHaveAccount: 'لديك حسابٌ بالفعل؟',
    firstName: 'الإسم الأول',
    lastName: 'الإسم الأخير',
    firstAndLastName: 'الإسم الأول والأخير',
    phoneNumber: 'رقم الهاتف',
    createAcctToViewPortal: 'أنشئ حسابًا لتتمكن من تصفح البوابة الإلكترونية لمؤسستك',
    yourEmailAddress: 'بريدك الإلكتروني',
    haventMetPasswordRequirements: 'لم تستوفِ كافة متطلبات كلمة المرور.',
    createProfile: 'إنشاء ملف شخصي',
    creatingUser: 'إنشاء مُستخدم',
    invalidEmail: 'بريد إلكتروني غير صحيح',
    invalidPhoneNumber: 'رقم هاتف خاطئ',
    phoneRequirements: 'يجب إدخال عشرة أرقام، دون فواصل خطيّة',
    createAccountSubheading: 'لنبدأ بجمع بعض المعلومات الأساسية!',
    enterMobilePhoneNumber: 'من فضلك قُم بإدخال رقم هاتفك الجوّال',
    requirements: 'الشروط الأساسية:',
    atLeast8Chars: 'ثمانية أرقام على الأقل',
    oneNumber: '•  رقم واحد',
    oneSpecialCharacter: '• رمز خاص واحد',
    passwordsMatch: '• كلمات المرور مُتطابقة',
    alreadyHaveAdminAccount: 'هل لديك بالفعل حساب حامل البطاقة؟',

    // Cardholder Portal
    notificationFeatureAnnouncement: 'اكتشف خصائص الإشعارات! يُمكنك الآن تلقي الإشعارات عبر البريد الإلكتورني بكل المُعاملات التي تقوم بها. قُم بالضغط على زرّ الإشعارات للمزيد من التفاصيل',
    cardActivityHistory: 'سجل نشاط استخدام البطاقة',
    transactions: 'المُعاملات',
    loadHistory: 'سجل الإيداع',
    date: 'التاريخ',
    transaction: 'المُعاملة',
    merchantCategory: 'فئة التاجر',
    amount: 'المبلغ',
    timeLoaded: 'وقت الإيداع',
    amountLoaded: 'المبلغ المُودَع',
    lockCard: 'إيقاف البطاقة',
    unlockCard: 'تفعيل البطاقة',
    home: 'الصفحة الرئيسية',
    emailNotificationsHeader: 'إشعارات البريد الإلكتروني',
    emailNotificationsSubheader1: 'استقبل الإشعارات عبر بريدك الإلكتروني لجميع الأنشطة التي تتم من خلال بطاقتك.',
    emailNotificationsSubheader2: ' يُمكنك إلغاء هذه الخاصية في أي وقت.',
    notifications: 'الإشعارات',
    recentTransactions: 'المُعاملات الحالية',
    recentTransactionsSubheader: 'استقبل اشعارات جميع مُعاملاتك الحالية لتتجنب الإحتيال، وتُرّشد الإنفاق',
    cardDeclines: 'رفض البطاقة',
    cardDeclinesSubheader: 'استقبل الإشعارات الخاصة برفض البطاقة لتعرف سبب الرفض',
    cardLoads: 'عمليات الإيداع التي تمت على البطاقة',
    cardLoadsSubheader: 'استقبل إشعارًا عندما يتم تحويل الأموال إلى بطاقتك',
    sendingTo: 'إرسال إلى:  ',
    saving: 'جارِ الحفظ...',
    save: 'حفظ',
    cardCanOnlyBeUsedAt: 'يسمح برنامجك باستخدام هذه البطاقة فقط لدى التجار التاليين:',
    moreInfoAboutMerchantRestrictions: 'لمزيد من المعلومات حول القيود المفروضة على التجار، يرجى الاتصال بالبرنامج الخاص بك مباشرة. GiveCard ليست مسؤولة عن هذه القيود.',

    // Transaction Table
    pending: 'قيد الإنتظار',
    pendingPurchases: 'المُشتريات المُعلّقة / قيد الإنتظار',
    pendingPurchasesDescription: 'المُشتريات المُعلّقة / قيد الإنتظار هي رسوم سمح التاجر بها، ولكن لم يتم تسجيلها بعد، وعادة ما يتم تسجيلها خلال خمسة أيام. وهي مدرجة في رصيدك الحالي.',
    cleared: 'مُكتملة',
    clearedPurchases: 'المُشتريات المُكتملة',
    clearedPurchasesDescription: 'المُشتريات المُكتملة هي عمليات الشراء التي وافق عليها التاجر',
    loadingTxs: 'جارِ تحميل المُعاملات',

    // Full Card Details
    errorGettingCardDetails: 'خطأ في الوصول لبيانات البطاقة',
    pleaseRefreshThePage: 'من فضلك أعد تحديث الصفحة',
    cardNumber: 'رقم البطاقة',
    howDoIUseMyVirtualCard: 'كيف يُمكنني استخدام بطاقتي الإفتراضية؟',
    usingAVirtualCard: 'استخدام البطاقة الإفتراضية',
    clickThe: 'اضغط هنا',
    toSeeFullCardDetails: 'لتظهر لك جميع بيانات البطاقة',
    cardCanBeUsedAnywhere: 'يمكنك استخدام هذه البطاقة في أي مكان يقبل النوع التالي من البطاقات prepaid Mastercard',
    cardInstructions: 'استخدم هذه البطاقة عبر الإنترنت من خلال نسخ ولصق التفاصيل المعروضة في هذه البوابة',
    digitalWalletInstructions: 'أضف بطاقتك إلى',
    digitalWalletInstructions2: 'عن طريق إدخال تفاصيل البطاقة على هاتفك. هُناك تعليمات أخرى مرتبطة بها.',
    orLowercase: 'أو',
    billingAddress: 'إذا كانت هُناك مُعاملة شرائية تتطلب عنوانًا لإرسال الفواتير، من فضلك استخدم العنوان التالي',
    needSupport: 'هل تحتاج إلى المزيد من الدعم؟',
    teamReadyToHelp: 'فريقُنا جاهز لمُساعدتك',
    pleaseEmail: 'فضلًا تواصل معنا عبر البريد الإلكتروني',
    call: 'اتصل بنا هاتفيًا',

    // Billing Address
    billingAddressTitle: 'عنوان إرسال الفواتير',
    yourBillingAddress: 'عنوان إرسال الفواتير الخاص بمُشترياتك عبر الإنترنت',
    pleaseUseThis: 'يُرجى استخدام عنوان إرسال الفواتير هذا عند إجراء مُعاملة عبر الإنترنت.',

    // Error Modal
    whoops: 'عفوًا',
    encounteredAnError: "'حدث خطأ ما!",
    tryAgain: 'حاول مرًة أخرى',
    ifProblemPersists: 'GiveCard إذا استمرت هذه المشكلة، فيرجى الاتصال بالمسؤول في',
    forDevelopers: 'للمُطوّرين',
  },

};

/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

/**
 * 
USPS_GROUND ShipmentMethodGround  "USPS_GROUND" is not recommended, we suggest using "USPS_PRIORITY" instead.
USPS_PRIORITY ShipmentMethodPriority  "USPS_PRIORITY" is the recommended shipment method. GiveCard covers the shipping cost for this shipment method.
UPS_NEXT_DAY ShipmentMethodNextDay  Charges vary for "UPS_NEXT_DAY" and will be billed to your business
UPS_SECOND_DAY ShipmentMethodSecondDay  Charges vary for "UPS_SECOND_DAY" and will be billed to your business
 */
export type OrderAndShipCardsParamsShipmentMethod = typeof OrderAndShipCardsParamsShipmentMethod[keyof typeof OrderAndShipCardsParamsShipmentMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderAndShipCardsParamsShipmentMethod = {
  USPS_GROUND: 'USPS_GROUND',
  USPS_PRIORITY: 'USPS_PRIORITY',
  UPS_NEXT_DAY: 'UPS_NEXT_DAY',
  UPS_SECOND_DAY: 'UPS_SECOND_DAY',
} as const;

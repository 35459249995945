import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import {
  getAuth, onAuthStateChanged, User,
} from 'firebase/auth';
import { ref } from 'vue';
import store from '@/store/store';
import globalProperties from '@/global';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'GiveCard Platform',
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/LoginView.vue'),
    meta: {
      hideNavBar: true,
      title: 'Login | GiveCard Platform',
    },
  },
  {
    path: '/onboard',
    name: 'createProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    props: true,
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/CreateUserOnboarding.vue'),
    meta: {
      authRequired: false,
      hideNavbar: false,
    },
  },
  {
    path: '/businessInfo',
    name: 'businessInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/BusinessInfo.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/:businessId/authorizedPerson',
    name: 'authorizedPerson',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/CreatePrimaryAuthorizedPerson.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/:businessId/authorizedPerson/welcome',
    name: 'authorizedPersonLanding',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/PrimaryAuthorizedPersonLandingPage.vue'),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/:businessId/authorizedPerson/info',
    name: 'authorizedPersonInfo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/PrimaryAuthorizedPersonForm.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/:businessId/authorizedPersonStatus',
    name: 'authorizedPersonStatus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/PrimaryAuthorizedPersonStatus.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/:businessId/addBeneficialOwners',
    name: 'addBeneficialOwners',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/AddBeneficialOwners.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/:businessId/beneficialOwnerForm/:beneficialOwnerId/info',
    name: 'beneficialOwnerForm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/BeneficialOwnerForm.vue'),
  },

  {
    path: '/:businessId/submitConfirmation',
    name: 'submitConfirmation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/SubmitConfirmation.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/:businessId/beneficialOwnerStatus',
    name: 'beneficialOwnerStatus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/BeneficialOwnerStatus.vue'),
    meta: {
      authRequired: false,
    },
  },

  {
    path: '/:businessId/manualReview',
    name: 'manualReview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/onboarding/ManualReview.vue'),
  },

  {
    path: '/cardholders',
    name: 'cardholders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cardholder/CardholdersView.vue'),
  },

  {
    path: '/cards',
    name: 'cards',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/CardDashboard.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/bank-transfers',
    name: 'banktransfers',
    component: () => import('../views/admin/BankTransferDashboard.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/cards/:cardId',
    name: 'singleCard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SingleCard.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/recipient/:recipientId',
    name: 'bankTransferRecipient',
    component: () => import('../views/admin/IndividualBankTransferRecipient.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/landingPage',
    name: 'landingPage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LandingPage.vue'),
  },

  {
    path: '/reports',
    name: 'reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/GeneratedReport.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/programs/reports',
    name: 'superBusinessReports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SuperBusinessReport.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/integrations',
    name: 'integrations',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/IntegrationsView.vue'),
    meta: {
      authRequired: true,
    },
  },

  {
    path: '/orders',
    name: 'orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/CardOrderView.vue'),
    meta: {
      authRequired: true,
      title: 'Card Orders | GiveCard Platform',
    },
  },

  {
    path: '/orders/:orderId',
    name: 'orderDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SingleOrderDetails.vue'),
    meta: {
      authRequired: true,
      title: 'Order Details | GiveCard Platform',
    },
  },

  {
    path: '/cardholder/dashboard',
    name: 'cardholderDashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cardholder/CardholderPortal.vue'),
    meta: {
      authRequired: true,
    },
    props: true,
  },

  {
    path: '/fundingAccount',
    name: 'fundingAccount',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/FundingAccountView.vue'),
    meta: {
      authRequired: true,
      title: 'Funding Account Details | GiveCard Platform',
    },
  },
  {
    path: '/members',
    name: 'members',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/MembersView.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/register/cardholder',
    name: 'registercardholder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/cardholder/CreateAccountVirtualCardholder.vue'),
    meta: {
      hideNavBar: true,
      title: 'Create An Account | GiveCard Platform',
    },
  },
  {
    path: '/register/member',
    name: 'registermember',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/CreateAccountNewMember.vue'),
    meta: {
      hideNavBar: true,
      title: 'Create An Account | GiveCard Platform',
    },
  },
  {
    path: '/programs',
    name: 'programs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ProgramsView.vue'),
    meta: {
      authRequired: true,
      hideNavBar: true,
      title: 'Programs | GiveCard Platform',
    },
  },
  {
    path: '/autoloads',
    name: 'autoloads',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AutoloadsDashboard.vue'),
    meta: {
      authRequired: true,
      title: 'Autoloads | GiveCard Platform',
    },
  },
  {
    path: '/autoload/:autoloadId',
    name: 'autoload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/IndividualAutoloadView.vue'),
    meta: {
      authRequired: true,
      title: 'Autoload | GiveCard Platform',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/SpendRulesDashboard.vue'),
    meta: {
      authRequired: true,
      title: 'Settings | GiveCard Platform',
    },
  },
  {
    path: '/import/order',
    name: 'cardOrderImport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/IssueCardsImportLanding.vue'),
    meta: {
      authRequired: true,
      title: 'Import | GiveCard Platform',
    },
  },
  {
    path: '/import/order/physical',
    name: 'physicalCardImport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ImportForPhysicalCards.vue'),
    meta: {
      authRequired: true,
      title: 'Physical Cards via Import | GiveCard Platform',
    },
  },
  {
    path: '/import/order/virtual',
    name: 'virtualCardImport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ImportForIssueVirtualCards.vue'),
    meta: {
      authRequired: true,
      title: 'Virtual Cards via Import | GiveCard Platform',
    },
  },
  {
    path: '/paybilt',
    name: 'paybiltLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/customcardholder/CustomCardholderLogin.vue'),
    meta: {
      title: 'Paybilt Cards',
      icon: '/paybilt.ico',
    },
  },
  {
    path: '/paybilt/cardholder',
    name: 'paybiltPortal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/customcardholder/CustomCardholderPortal.vue'),
    meta: {
      authRequired: true,
      title: 'Paybilt Cards',
      icon: '/paybilt.ico',
    },
  },
  {
    path: '/paybilt/register/cardholder',
    name: 'registerPaybiltCardholder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/customcardholder/CustomCreateVirtualCardAccount.vue'),
    meta: {
      hideNavBar: true,
      title: 'Create An Account | Paybilt Cards',
      icon: '/paybilt.ico',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title !== undefined) {
    document.title = `${to.meta.title}`;
  } else {
    document.title = 'GiveCard Platform';
  }
  if (to.meta.icon !== undefined) {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon instanceof HTMLLinkElement) {
      favicon.href = `${to.meta.icon}` || '/favicon.ico';
    }
  }
  if ((window.location.host === 'cards.paybilt.com' || window.location.host === 'www.cards.paybilt.com' || window.location.host === 'devcards.paybilt.com') && to.path === '/register/cardholder') {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      next(`/paybilt/register/cardholder?token=${token}`);
    } else {
      next('/paybilt/register/cardholder'); // If token is not present, redirect without token
    }
    return;
  }
  if ((window.location.host === 'cards.paybilt.com' || window.location.host === 'www.cards.paybilt.com' || window.location.host === 'devcards.paybilt.com') && to.path === '/') {
    next('/paybilt');
    return;
  }

  const auth = getAuth();
  if (to.matched.some((record) => record.meta.authRequired)) {
    const user = ref<User | null>(auth.currentUser);

    onAuthStateChanged(auth, (_user) => {
      user.value = _user;
      if (user.value) {
        if (!to.fullPath.includes('cardholder')
          && (store.state.business.id === undefined
            || store.state.user.id === undefined
            || store.state.baseBusiness.id === undefined)) {
          globalProperties.store.refetchUserandBusiness(auth.currentUser?.uid || '');
        } else if ((to.fullPath.includes('cardholder/dashboard')
            || (to.fullPath.includes('paybilt/cardholder'))) && store.state.cardholder.id === undefined) {
          globalProperties.store.refetchCardholder(to, auth.currentUser?.uid || '');
        }
        next();
      } else {
        router.push('/');
      }
    });
  } else {
    next();
  }
});
export default router;

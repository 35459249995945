/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */

export type CreateBusinessProgramParamsBodyProductType = typeof CreateBusinessProgramParamsBodyProductType[keyof typeof CreateBusinessProgramParamsBodyProductType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateBusinessProgramParamsBodyProductType = {
  cards: 'cards',
  bank_transfers: 'bank_transfers',
  on_demand_cards: 'on_demand_cards',
} as const;
